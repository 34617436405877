<template>
    <div>
        <CRow class="mb-3">
            <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
                <CButton
                    color="edit"
                    class="mr-2"
                    v-c-tooltip="{
                        content: $t('label.return'),
                        placement: 'top',
                    }"
                    size="sm"
                    @click="$emit('Close');"
                >
                    <CIcon name="cil-chevron-left-alt" />
                </CButton>
            </CCol>
        </CRow>
      <CRow class="mt-2">
        <CCol sm="12">
          <CTabs variant="tabs" :active-tab="this.tabinfoIndex"  @update:activeTab="handleTab">
            <CTab :title="$t('label.generalInfo')">
              <form>
                <CRow class="justify-content-center mt-3 px-2">
                  <CCol sm="12" lg="6">
                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right required">{{$t('label.condition')}}</label>
                      <div class="input-group col-sm-12 col-lg-7">
                        <CSelect
                          size="sm"
                          v-model="$v.conditionSelected.$model"
                          :addInputClasses="'not-border'"
                          class="mb-0 px-0 col-sm-12"
                          :options="ConditionOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.conditionSelected)"
                        >
                        </CSelect>
                        <div class="text-invalid-feedback" v-if="$v.conditionSelected.$error">
                          {{ errorMessage($v.conditionSelected) }}
                        </div>
                      </div>
                    </div>
                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right">{{$t('label.class')}}</label>
                      <div class="input-group col-sm-12 col-lg-7">
                        <CSelect
                          size="sm"
                          v-model="$v.containerClassSelected.$model"
                          :addInputClasses="'not-border'"
                          class="mb-0 px-0 col-sm-12"
                          :options="ContainerClassOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.containerClassSelected)"
                        >
                        </CSelect>
                        <div class="text-invalid-feedback" v-if="$v.containerClassSelected.$error">
                          {{ errorMessage($v.containerClassSelected) }}
                        </div>
                      </div>
                    </div>
                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right required">{{$t('label.loadPort')}}</label>
                      <div class="input-group col-sm-12 col-lg-7">
                        <CInput
                          v-model="loadPort"
                          size="sm"
                          required 
                          disabled
                          class="mb-0 pl-0 pr-1 col-sm-6"
                          :is-valid="hasError($v.loadPort)"
                        />
                        <CSelect
                          size="sm"
                          v-model="$v.loadPortSelected.$model"
                          :addInputClasses="'not-border'"
                          @change="filterLoadPort($event.target.value)"
                          class="mb-0 px-0 col-sm-6"
                          :options="ContainerMasterPort"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.loadPortSelected)"
                        >
                        </CSelect>
                        <div class="text-invalid-feedback" v-if="$v.loadPortSelected.$error || $v.loadPort.$error">
                          {{ $v.loadPortSelected.$error ? errorMessage($v.loadPortSelected) : errorMessage($v.loadPort) }}
                        </div>
                      </div>
                    </div>
                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right required">{{$t('label.dischargePort')}}</label>
                      <div class="input-group col-sm-12 col-lg-7">
                        <CInput
                          v-model="dischargePort"
                          size="sm"
                          required 
                          disabled
                          class="mb-0 pl-0 col-sm-6 pr-1"
                          :is-valid="hasError($v.dischargePort)"
                        />
                        <CSelect
                          size="sm"
                          v-model="$v.dischargePortSelected.$model"
                          :addInputClasses="'not-border'"
                          @change="filterDischargePort($event.target.value)"
                          class="mb-0 px-0 col-sm-6"
                          :options="ContainerMasterPort"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.dischargePortSelected)"
                        >
                        </CSelect>
                        <div class="text-invalid-feedback" v-if="$v.dischargePortSelected.$error || $v.dischargePort.$error">
                          {{ $v.dischargePortSelected.$error ? errorMessage($v.dischargePortSelected) : errorMessage($v.dischargePort) }}
                        </div>
                      </div>
                    </div>
                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right">{{$t('label.TransshipmentPort')}}</label>
                      <div class="input-group col-sm-12 col-lg-7">
                        <CInput
                          v-model="TransshipmentPort"
                          size="sm"
                          required 
                          disabled
                          class="mb-0 pl-0 col-sm-6 pr-1"
                        />
                        <CSelect
                          size="sm"
                          v-model="$v.TransshipmentPortSelected.$model"
                          :addInputClasses="'not-border'"
                          @change="filterTranshipmentPort($event.target.value)"
                          class="mb-0 px-0 col-sm-6"
                          :options="ContainerPort"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.TransshipmentPortSelected)"
                        >
                        </CSelect>
                        <div class="text-invalid-feedback" v-if="$v.TransshipmentPortSelected.$error">
                          {{ errorMessage($v.TransshipmentPortSelected) }}
                        </div>
                      </div>
                    </div>
                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right">{{$t('label.DeliveryPort')}}</label>
                      <div class="input-group col-sm-12 col-lg-7">
                        <CInput
                          v-model="deliveryPort"
                          size="sm"
                          class="mb-0 pl-0 col-sm-6 pr-1"
                          required 
                          disabled
                        />
                        <CSelect
                          size="sm"
                          v-model="$v.deliveryPortSelected.$model"
                          :addInputClasses="'not-border'"
                          @change="filterDeliveryPort($event.target.value)"
                          class="mb-0 px-0 col-sm-6"
                          :options="ContainerPort"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.deliveryPortSelected)"
                        >
                        </CSelect>
                        <div class="text-invalid-feedback" v-if="$v.deliveryPortSelected.$error">
                          {{ errorMessage($v.deliveryPortSelected) }}
                        </div>
                      </div>
                    </div>
                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right">{{$t('label.CargoOriginPort')}}</label>
                      <div class="input-group col-sm-12 col-lg-7">
                        <CInput
                          v-model="cargoOriginPort"
                          size="sm"
                          class="mb-0 pl-0 col-sm-6 pr-1"
                          required 
                          disabled
                        />
                        <CSelect
                          size="sm"
                          v-model="$v.cargoOriginPortSelected.$model"
                          :addInputClasses="'not-border'"
                          @change="filterCargoOriginPort($event.target.value)"
                          class="mb-0 px-0 col-sm-6"
                          :options="ContainerPort"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.cargoOriginPortSelected)"
                        >
                        </CSelect>
                        <div class="text-invalid-feedback" v-if="$v.cargoOriginPortSelected.$error">
                          {{ errorMessage($v.cargoOriginPortSelected) }}
                        </div>
                      </div>
                    </div>
                  </CCol>
  
                  <CCol sm="12" lg="6">
                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right required">{{$t('label.largo')}}</label>
                      <div class="input-group col-sm-12 col-lg-7">
                        <CInput
                          ref="lenghtInput"
                          v-money="money"
                          size="sm"
                          required 
                          class="mb-0 pl-0 col-sm-6 pr-1"
                          :is-valid="hasError($v.length)"
                          v-model="$v.length.$model"
                          maxlength="10"
                          disabled
                        />
                        <CSelect
                          size="sm"
                          @change="filterLength($event.target.value)"
                          :addInputClasses="'not-border'"
                          class="mb-0 px-0 col-sm-6"
                          :options="UnitMeasureDimrLengthOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.lengthSelected)"
                          v-model="$v.lengthSelected.$model"
                        ></CSelect>
                        <div class="text-invalid-feedback" v-if="$v.length.$error||$v.lengthSelected.$error">
                          <div v-if="$v.lengthSelected.$error">
                            {{ errorMessage($v.lengthSelected) }}
                          </div>
                          <div v-else>
                            {{ !$v.length.maxValue ? `${$t('validation.maxValue')}: 99.999,99` : errorMessage($v.length) }}
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right required">{{$t('label.height')}}</label>
                      <div class="input-group col-sm-12 col-lg-7">
                        <CInput
                          ref="heightInput"
                          v-money="money"
                          size="sm"
                          required 
                          class="mb-0 pl-0 pr-1 col-sm-6"
                          :is-valid="hasError($v.heigth)"
                          v-model="$v.heigth.$model"
                          maxlength="10"
                          disabled
                        />
                        <CSelect
                          size="sm"
                          @change="filterHeigth($event.target.value)"
                          :addInputClasses="'not-border'"
                          class="mb-0 px-0 col-sm-6"
                          :options="UnitMeasureDimrHeightOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.heigthSelected)"
                          v-model="$v.heigthSelected.$model"
                        ></CSelect>
                        <div class="text-invalid-feedback" v-if="$v.heigth.$error||$v.heigthSelected.$error">
                          <div v-if="$v.heigthSelected.$error">
                            {{ errorMessage($v.heigthSelected) }}
                          </div>
                          <div v-else>
                            {{ !$v.heigth.maxValue ? `${$t('validation.maxValue')}: 99.999,99` : errorMessage($v.heigth) }}
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right required">{{$t('label.weight')}}</label>
                      <div class="input-group col-sm-12 col-lg-7">
                        <CInput
                          v-money="money"
                          size="sm"
                          required 
                          class="mb-0 pl-0 pr-1 col-sm-6"
                          ref="weigthInput"
                          :is-valid="hasError($v.weight)"
                          v-model="$v.weight.$model"
                          maxlength="10"
                          :disabled="!weightSelected"
                        >
                        </CInput>
                        <CSelect
                          size="sm"
                          @change="filterWeight($event.target.value)"
                          :addInputClasses="'not-border'"
                          class="mb-0 px-0 col-sm-6"
                          :options="UnitMeasureWeigthOptions"
                          :is-valid="hasError($v.weightSelected)"
                          :placeholder="$t('label.select')"
                          v-model="$v.weightSelected.$model"
                        />
                        <div class="text-invalid-feedback" v-if="$v.weight.$error||$v.weightSelected.$error">
                          <div v-if="$v.weightSelected.$error">
                            {{ errorMessage($v.weightSelected) }}
                          </div>
                          <div v-else>
                            {{ !$v.weight.limit ? `${$t('label.container_data.container_weight')} ${maxWeigthNumber}` : 
                              !$v.weight.maxValue ?  `${$t('validation.maxValue')}: 99.999,99` : errorMessage($v.weight) }}
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right">{{$t('label.vgm')}}</label>
                      <div class="input-group col-sm-12 col-lg-7">
                        <CInput
                          :is-valid="hasError($v.vgm)"
                          v-model="$v.vgm.$model"
                          v-money="money"
                          size="sm"
                          required 
                          class="mb-0 pl-0 pr-1 col-sm-6"
                          ref="vgmInput"
                          maxlength="10"
                          :disabled="!vgmSelected"
                        >
                        </CInput>
                        <CSelect
                          size="sm"
                          @change="filterVgm($event.target.value)"
                          :addInputClasses="'not-border'"
                          class="mb-0 px-0 col-sm-6"
                          :options="UnitMeasureVgmOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.vgmSelected)"
                          v-model="$v.vgmSelected.$model"
                        >
                        </CSelect>
                        <div class="text-invalid-feedback" v-if="$v.vgm.$error||$v.vgmSelected.$error">
                          <div v-if="$v.vgmSelected.$error">
                            {{ errorMessage($v.vgmSelected) }}
                          </div>
                          <div v-else>
                            {{ !$v.vgm.limit ? `${$t('label.container_data.container_weight')} ${maxVgmNumber}` : 
                              !$v.vgm.maxValue ?  `${$t('validation.maxValue')}: 99.999,99` : errorMessage($v.vgm) }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <CInput
                      v-uppercase
                      size="sm"
                      class="mb-3"
                      addLabelClasses="text-right"
                      :label="$t('label.description')"
                      :horizontal="{label:'col-sm-12 col-lg-4 text-right', input:'col-sm-12 col-lg-7'}"
                      :is-valid="hasError($v.DescriptionType)"
                      :invalid-feedback="errorMessage($v.DescriptionType)"
                      v-model="$v.DescriptionType.$model"
                    >
                    </CInput>
                  </CCol>
                </CRow>
              </form>
            </CTab>
            <CTab :title="$t('label.special')">
             <form>
                <CRow class="justify-content-center mt-3">
                  <CCol sm="12" lg="5">
                    <CCard>
                      <CCardHeader class="text-center bg-dark text-white" style="background-color: #9e9e9e;">
                        <b>{{ $t('label.ReeferInfo') }}</b> 
                      </CCardHeader>
                      <CCardBody style="background-color: #f5f5f5;">
                        <div class="form-group row mt-4 mb-3">
                          <div  class="col-sm-12 col-lg-6 text-right pr-1">{{ `${this.$t('label.reefer')}`}}</div>
                          <div class="col-sm-12 col-lg-6 d-flex align-items-center">
                            <CSwitch
                              color="watch"
                              size="sm"
                              variant="3d"
                              type="checkbox"
                              :checked.sync="dryReefer"
                              :disabled="true"
                              @update:checked="dryReefer = $event"
                            />
                          </div>
                        </div>
  
                        <div class="form-group row mb-3" v-if="dryReefer">
                          <div  class="col-sm-12 col-lg-6 text-right pr-1">{{ `${this.$t('label.pluggedIn')}`}}</div>
                          <div class="col-sm-12 col-lg-6 d-flex align-items-center">
                            <CSwitch
                              color="watch"
                              size="sm"
                              variant="3d"
                              type="checkbox"
                              :checked.sync="plugged"
                              @update:checked="plugged = $event"
                              :disabled="!dryReefer"
                            />
                          </div>
                        </div>
  
                        <CSelect
                          addLabelClasses="text-right text-label-black"
                          class="mb-3"
                          size="sm"
                          :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
                          :label="`${this.$t('label.connectedTo')}`" 
                          :options="TpPowerPlugOptions"
                          :is-valid="hasError($v.tpPowerPlugSelected)"
                          :invalid-feedback="errorMessage($v.tpPowerPlugSelected)"
                          v-model="$v.tpPowerPlugSelected.$model"
                          :disabled="(!plugged || !dryReefer)"
                        ></CSelect>
  
                        <CInput
                          v-uppercase
                          size="sm"
                          class="mb-3"
                          addLabelClasses="text-right"
                          :label="$t('label.temperature')"
                          :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
                          :is-valid="hasError($v.reeferSetting)"
                          :invalid-feedback="errorMessage($v.reeferSetting)"
                          v-model="$v.reeferSetting.$model"
                          type="number"
                          :disabled="!dryReefer"
                        >
                        </CInput>
  
                        <CSelect
                          addLabelClasses="text-right text-label-black"
                          size="sm"
                          class="mb-3"
                          :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
                          :label="`${this.$t('label.measureUnit')} RF`" 
                          @change="filterRf($event.target.value)"
                          :options="UnitMeasureUnitRfOptions"
                          :is-valid="hasError($v.measureUnitRfSelected)"
                          :invalid-feedback="errorMessage($v.measureUnitRfSelected)"
                          v-model="$v.measureUnitRfSelected.$model"
                          :disabled="!dryReefer"
                        ></CSelect>
  
                        <CInput
                          v-uppercase
                          size="sm"
                          class="mb-3"
                          addLabelClasses="text-right"
                          :label="`${$t('label.MaximumRange')} RF`"
                          :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
                          ref="MaximumtInput"
                          :is-valid="hasError($v.maximunRangeRf)"
                          :invalid-feedback="errorMessage($v.maximunRangeRf)"
                          v-model="$v.maximunRangeRf.$model"
                          :disabled="!dryReefer"
                        >
                        </CInput>
  
                        <CInput
                          v-uppercase
                          size="sm"
                          class="mb-3"
                          addLabelClasses="text-right"
                          :label="`${$t('label.MinimumRange')} RF`"
                          :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
                          :is-valid="hasError($v.minimunRangeRf)"
                          :invalid-feedback="errorMessage($v.minimunRangeRf)"
                          v-model="$v.minimunRangeRf.$model"
                          ref="MinimumInput"
                          :disabled="!dryReefer"
                        >
                        </CInput>
                        
                      </CCardBody>
                    </CCard>
                  </CCol>
  
                  <CCol sm="12" lg="5">
                    <CCard>
                      <CCardHeader class="text-center bg-dark text-white" style="background-color: #9e9e9e;">
                        <b>{{ $t('label.OversizeInfo') }}</b>
                      </CCardHeader>
                      <CCardBody style="background-color: #f5f5f5;">
  
                        <CSelect
                          size="sm"
                          class="mb-3"
                          :is-valid="hasError($v.measureUnitDinSelected)"
                          :invalid-feedback="errorMessage($v.measureUnitDinSelected)"
                          v-model="$v.measureUnitDinSelected.$model"
                          @change="filterMeasureDin($event.target.value)"
                          addLabelClasses="text-right"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-6'}"
                          :label="`${this.$t('label.measureUnit')} DM`"
                          :addInputClasses="'not-border'"
                          :options="UnitMeasureUnitDinOptions"
                          :placeholder="$t('label.select')"
                          :disabled="( !FgApplyOversize )"
                        ></CSelect>
  
                        <CInput
                          v-uppercase
                          size="sm"
                          class="mb-3"
                          :is-valid="hasError($v.DimFront)"
                          :invalid-feedback="errorMessage($v.DimFront)"
                          v-model="$v.DimFront.$model"
                          addLabelClasses="text-right"
                          :label="$t('label.DimFront')"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-6'}"
                          v-money="money"
                          ref="DimFrontInput"
                          :disabled="( !FgApplyOversize )"
                        >
                        </CInput>
  
                        <CInput
                          v-uppercase
                          size="sm"
                          class="mb-3"
                          addLabelClasses="text-right"
                          :label="$t('label.DimBack')"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-6'}"
                          :is-valid="hasError($v.DimBack)"
                          :invalid-feedback="errorMessage($v.DimBack)"
                          v-model="$v.DimBack.$model"
                          v-money="money"
                          ref="DimBackInput"
                          :disabled="( !FgApplyOversize )"
                        >
                        </CInput>
  
                        <CInput
                          v-uppercase
                          size="sm"
                          class="mb-3"
                          addLabelClasses="text-right"
                          :label="$t('label.DimLeft')"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-6'}"
                          :is-valid="hasError($v.DimLeft)"
                          :invalid-feedback="errorMessage($v.DimLeft)"
                          v-model="$v.DimLeft.$model"
                          v-money="money"
                          ref="DimLeftInput"
                          :disabled="( !FgApplyOversize )"
                        >
                        </CInput>
  
                        <CInput
                          v-uppercase
                          size="sm"
                          class="mb-3"
                          addLabelClasses="text-right"
                          :label="$t('label.DimRight')"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-6'}"
                          :is-valid="hasError($v.DimRight)"
                          :invalid-feedback="errorMessage($v.DimRight)"
                          v-model="$v.DimRight.$model"
                          v-money="money"
                          ref="DimRightInput"
                          :disabled="( !FgApplyOversize )"
                        >
                        </CInput>
  
                        <CInput
                          v-uppercase
                          size="sm"
                          class="mb-3"
                          addLabelClasses="text-right"
                          :label="$t('label.DimTop')"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-6'}"
                          :is-valid="hasError($v.DimTop)"
                          :invalid-feedback="errorMessage($v.DimTop)"
                          v-model="$v.DimTop.$model"
                          v-money="money"
                          ref="DimToptInput"
                          :disabled="( !FgApplyOversize )"
                        >
                        </CInput>
                      </CCardBody>
                    </CCard>
                  </CCol>
                </CRow>
              </form>
            </CTab>
            <CTab :title="$t('label.LastTransaction')">
              <CTabs variant="tabs" :active-tab="0" class="mt-3">
                <CTab :title="$t('label.visit')">
                  <CCard class="card-border">
                    <CRow class="justify-content-center px-3">
                      <CCol sm="12" lg="6">
                        <CInput
                          v-uppercase
                          size="sm"
                          addLabelClasses="text-right"
                          :label="`${$t('label.visit')} / ${$t('label.voyage')}`"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-6'}"
                          v-model="VisitVoyage"
                          disabled
                        />
                        <CInput
                          v-uppercase
                          size="sm"
                          addLabelClasses="text-right"
                          :label="$t('label.movement')"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-6'}"
                          v-model="VisitMovement"
                          disabled
                        />
                        <CInput
                          v-uppercase
                          size="sm"
                          addLabelClasses="text-right"
                          :label="$t('label.branch')"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-6'}"
                          v-model="VisitBranch"
                          disabled
                        />
                      </CCol>  
                      <CCol sm="12" lg="6">
                        <CInput
                          v-uppercase
                          size="sm"
                          addLabelClasses="text-right"
                          :label="`ATA - ATD`"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-6'}"
                          v-model="AtaAtd"
                          disabled
                        />
                        <CInput
                          v-uppercase
                          size="sm"
                          addLabelClasses="text-right"
                          :label="$t('label.confirmationDate')"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-6'}"
                          v-model="VisitConfirmationDate"
                          disabled
                        />
                      </CCol>
                    </CRow>
                  </CCard>
                </CTab>
              </CTabs>
              <CTabs variant="tabs" :active-tab="0">
                <CTab :title="$t('label.yard')">
                  <CCard class="card-border">
                    <CRow class="justify-content-center px-3">
                      <CCol sm="12" lg="6">
                        <CInput
                          v-uppercase
                          size="sm"
                          addLabelClasses="text-right"
                          :label="$t('label.branch')"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-6'}"
                          v-model="YardBranch"
                          disabled
                        />
                        <CInput
                          v-uppercase
                          size="sm"
                          addLabelClasses="text-right"
                          :label="$t('label.Entry')"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-6'}"
                          v-model="YardEntry"
                          disabled
                        />
                        <CInput
                          v-uppercase
                          size="sm"
                          addLabelClasses="text-right"
                          :label="$t('label.daysInYard')"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-6'}"
                          v-model="DaysInYard"
                          disabled
                        />
                      </CCol>  
                      <CCol sm="12" lg="6">
                        <CInput
                          v-uppercase
                          size="sm"
                          addLabelClasses="text-right"
                          :label="$t('label.yard')"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-6'}"
                          v-model="YardName"
                          disabled
                        />
                        <CInput
                          v-uppercase
                          size="sm"
                          addLabelClasses="text-right"
                          :label="$t('label.dispatch')"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-6'}"
                          v-model="YardDispatch"
                          disabled
                        />
                      </CCol>
                    </CRow>
                  </CCard>
                </CTab>
              </CTabs>
            </CTab>
          </CTabs>
        </CCol>
      </CRow>
      <CRow>
      <CCol sm="12" class="d-flex align-items-center justify-content-end mb-2">
          <CButton
            square
            color="add"
            class="d-flex align-items-center"
            @click="Submit()"
            :disabled="isSubmit"
          >
            <CIcon name="checkAlt"/>{{$t('button.accept')}}
          </CButton>
        </CCol>
      </CRow>
    </div>
  </template>
  <script>
  import { mapState } from 'vuex';
  import GeneralMixin from '@/_mixins/general';
  import ReportesVisitas from '@/_mixins/reportesVisitas';
  import ModalMixin from '@/_mixins/modal';
  import { StepTwo } from '@/_validations/yard-management/movements/newEntryValidations';
  import { NumberFormater, DateFormater } from '@/_helpers/funciones';
  import UpperCase from '@/_validations/uppercase-directive';
  import { VMoney } from 'v-money';
  
  function data() {
    return {
      money: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 2,
          masked: false
      },
      isSubmit: false,
      yardCargoId:'',
      tabinfoIndex:0,
      //general info
      conditionSelected:'',
      optionCondition:[],
      containerClassSelected:'',
      optionContainerClass:[],
      loadPort:'',
      loadPortSelected:'',
      dischargePort:'',
      dischargePortSelected:'',
      TransshipmentPort:'',
      TransshipmentPortSelected:'',
      deliveryPort:'',
      deliveryPortSelected:'',
      cargoOriginPort:'',
      cargoOriginPortSelected:'',
      optionMasterPort:[],
      optionPort:[],
      //ISO AND SEALS
      DescriptionType:'',
      unitMeasureLengthDim:[],
      length:0,
      lengthSelected:'',
      afterUnitLength:'',
      unitMeasureHeightDim:[],
      heigth:0,
      heigthSelected:'',
      afterUnitHeigth:'',
      UnitMeasureWeigth:[],
      weight:0,
      weightSelected:'',
      afterUnitWeight:'',
      UnitMeasureVgm:[],
      vgm:0,
      vgmSelected:'',
      afterUnitVgm:'',
      maxGrossWeigthJson:[],
      weigthEmptyJson:[],
      maxLoadJson:[],
      //SPECIAL
      tpPowerPlug:[],
      tpPowerPlugSelected:'',
      dryReefer:false,
      plugged:false,
      measureUnitRf:[],
      measureUnitRfSelected:'',
      afterUnitRf:'',
      reeferSetting:'',
      minimunRangeRf: 0,
      maximunRangeRf: 0,
      DimBack: 0,
      DimFront: 0,
      DimLeft: 0,
      DimRight: 0,
      DimTop: 0,
      measureUnitDin:[],
      measureUnitDinSelected:'',
      afterUnitDin:'',
      FgApplyOversize:false,
      //ULTIMA TRANSACCION
      VisitVoyage: '',
      VisitBranch: '',
      AtaAtd: '',
      VisitConfirmationDate: '',
      YardBranch: '',
      YardEntry: '',
      YardName: '',
      YardDispatch: '',
    };
  }
  
  function handleTab(tab) {
    this.tabinfoIndex = tab;
  }
  
  //methods
  function Submit() {
    try {
      this.$store.state.yardManagement.loading = true;
      this.isSubmit = true;
      this.$v.$touch();
      if (this.$v.$error||!this.branch?.CompanyBranchId){
        if (!this.branch?.CompanyBranchId) {
          throw this.$t('label.MissingBranchId');
        } else {
          throw this.$t('label.errorsPleaseCheck');
        }
      }

      let CargoJson = {
        CompanyBranchId: this.branch.CompanyBranchId,
        ItineraryId: this.NewEntryForm.Itinerary,
        BookingNro: this.NewEntryForm.Booking,
        ClientTpId: this.NewEntryForm.Client,
        FgActShipperOwn: this.NewEntryForm.FgShipperOwned ? 1 : 0,
        ShipperOwn: this.NewEntryForm.FgShipperOwned ? this.NewEntryForm.ShipperOwned : '',
        Procedence: this.NewEntryForm.Procedence,
        CargoId: this.NewEntryForm.CargoId ?? '',
        ContainerId: this.NewEntryForm.ContainerId ?? '',
        ContainerCode: this.NewEntryForm.ContainerCode,
        SizeId: this.NewEntryForm.Size,
        TpCargoClassId: this.containerClassSelected,
        TpCargoStatusId: this.NewEntryForm.Status,
        ShippingLineId: this.NewEntryForm.ShippingLine,
        LoadPortId: this.loadPortSelected, 
        DischargePortId: this.dischargePortSelected, 
        TranshipmentPortId: this.TransshipmentPortSelected, 
        DeliveryPortId: this.deliveryPortSelected, 
        OriginalPortLoadingId: this.cargoOriginPortSelected,
        MetadataId: this.NewEntryForm.Iso,
        DescriptionType: this.DescriptionType,
        Weigth: Number.parseFloat(this.weight.replace(/[.]/g, '').replace(',', '.')).toFixed(2)?? 0,
        UnitMeasureWeigth: this.weightSelected,
        Vgm: Number.parseFloat(this.vgm.replace(/[.]/g, '')?.replace(',', '.')).toFixed(2) ?? 0,
        UnitMeasureVgm: this.vgmSelected,
        PortActivityId: this.NewEntryForm.PortActivity,
        TpCargoConditionId: this.conditionSelected,
        DryReefer: this.dryReefer ? 1 : 0,
        UnitMeasureRf: this.measureUnitRfSelected,
        Plugged: this.plugged? 1 : 0,
        TpPowerPlugId: this.tpPowerPlugSelected,
        ReeferSetting: this.reeferSetting,
        MinimumRangeRf: this.minimunRangeRf,
        MaximumRangeRf: this.maximunRangeRf,
        UnitMeasureDim: this.measureUnitDinSelected, 
        DimFront: Number.parseFloat(this.DimFront.replace(/[.]/g, '').replace(',', '.')).toFixed(2)?? 0,
        DimBack: Number.parseFloat(this.DimBack.replace(/[.]/g, '').replace(',', '.')).toFixed(2)?? 0,
        DimLeft: Number.parseFloat(this.DimLeft.replace(/[.]/g, '').replace(',', '.')).toFixed(2)?? 0,
        DimRight: Number.parseFloat(this.DimRight.replace(/[.]/g, '').replace(',', '.')).toFixed(2)?? 0,
        DimTop: Number.parseFloat(this.DimTop.replace(/[.]/g, '').replace(',', '.')).toFixed(2)?? 0,
      }

      this.$http.post('YardContainer-insert', CargoJson, { root: 'CargoJson' })
        .then(async (response) => {
          let Container = response.data.data[0] ?? {};
          this.notifySuccess({text: Container.Response});
          await this.Reset();
          await this.$emit('SubmitNewEntry', Container);
        }).catch((err) => {
          this.notifyError({text: err});
        }).finally(() => {
          this.isSubmit = false;
          this.$store.state.yardManagement.loading = false;
        });
    } catch (error) {
      this.isSubmit = false;
      this.$store.state.yardManagement.loading = false;
      this.notifyError({text: error});
    }
  }
  
  function filterLoadPort(event) {
    this.loadPortSelected = event 
  
    if(this.loadPortSelected && this.loadPortSelected!=''){
      let loadPort = this.optionMasterPort?.filter((e) => { return  this.loadPortSelected === e.PortId }) ?? [];
      this.loadPort = loadPort[0]?.PortName;
    }
    else
    {
      this.loadPortSelected = '';
      this.loadPort = '';
    }
  }
  
  function filterDischargePort(event) {
    this.dischargePortSelected = event 
  
    if(this.dischargePortSelected && this.dischargePortSelected!=''){
      let dischargePort = this.optionMasterPort?.filter((e) => { return  this.dischargePortSelected === e.PortId }) ?? [];
      this.dischargePort = dischargePort[0]?.PortName;
    }
    else
    {
      this.dischargePortSelected = '';
      this.dischargePort = '';
    }
  }

  function filterTranshipmentPort(event) {
    this.TransshipmentPortSelected  = event 
  
    if(this.TransshipmentPortSelected && this.TransshipmentPortSelected!=''){
      let Transshipment = this.optionPort?.filter((e) => { return  this.TransshipmentPortSelected === e.PortId }) ?? [];
      this.TransshipmentPort = Transshipment[0]?.PortName;
      
    }
    else
    {
      this.TransshipmentPortSelected = '';
      this.TransshipmentPort = '';
    }
  }
  
  function filterDeliveryPort(event) {
    this.deliveryPortSelected = event 
  
    if(this.deliveryPortSelected && this.deliveryPortSelected!=''){
      let deliveryPort = this.optionPort?.filter((e) => { return  this.deliveryPortSelected === e.PortId }) ?? [];
      this.deliveryPort = deliveryPort[0]?.PortName;
    }
    else
    {
      this.deliveryPortSelected = '';
      this.deliveryPort = '';
    }
  }
  
  function filterCargoOriginPort(event) {
    this.cargoOriginPortSelected = event 
  
    if(this.cargoOriginPortSelected && this.cargoOriginPortSelected!=''){
      let cargoOriginPort = this.optionPort?.filter((e) => { return  this.cargoOriginPortSelected === e.PortId }) ?? [];
      this.cargoOriginPort = cargoOriginPort[0]?.PortName;
    }
    else
    {
      this.cargoOriginPortSelected = '';
      this.cargoOriginPort = '';
    }
  }
  
  //ISO
  function filterLength(event) {
    this.lengthSelected = event 
    let inputLenght = this.$refs?.lenghtInput?.$el?.querySelector("input");
  
    if(this.lengthSelected && this.afterUnitLength != this.lengthSelected){
      this.Conversions(this.lengthSelected, this.afterUnitLength, this.length, inputLenght );
      this.length = inputLenght.value;
      this.afterUnitLength = this.lengthSelected;
    }else if (this.lengthSelected) {
      this.afterUnitLength = this.lengthSelected;
    }
  }
  
  function filterHeigth(event) {
    this.heigthSelected = event 
    let inputHeight = this.$refs?.heightInput?.$el?.querySelector("input");
  
    if(this.heigthSelected && this.afterUnitHeigth != this.heigthSelected){
      this.afterUnitHeigth !="" && this.Conversions(this.heigthSelected, this.afterUnitHeigth, this.heigth, inputHeight );
      this.heigth = inputHeight.value;
      this.afterUnitHeigth = this.heigthSelected;
    }
    else if (this.heigthSelected) {
      this.afterUnitHeigth = this.heigthSelected;
    }
  }
  
  async function filterWeight(event) {
    this.weightSelected = event 
    let inputWeigth = this.$refs?.weigthInput?.$el?.querySelector("input");
    if(this.weightSelected && this.afterUnitWeight != this.weightSelected){
      this.afterUnitWeight !="" && await this.Conversions(this.weightSelected, this.afterUnitWeight, this.weight, inputWeigth );
      this.weight = inputWeigth.value;
      this.afterUnitWeight = this.weightSelected;
    }
    else if (this.weightSelected) {
      this.afterUnitWeight = this.weightSelected;
    }
  }
  
  function filterVgm(event) {
    this.vgmSelected = event 
    let inputVgm = this.$refs?.vgmInput?.$el?.querySelector("input");
  
    if(this.vgmSelected && this.afterUnitVgm != this.vgmSelected){
      this.afterUnitVgm !="" && this.Conversions(this.vgmSelected, this.afterUnitVgm, this.vgm, inputVgm );
      this.vgm = inputVgm.value;
      this.afterUnitVgm = this.vgmSelected;
    }
    else if (this.vgmSelected) {
      this.afterUnitVgm = this.vgmSelected;
    }
  }
  
  function filterMeasureDin(event) {
    this.measureUnitDinSelected = event;
    let inputDimFront = this.$refs?.DimFrontInput?.$el?.querySelector("input");
    let inputDimBack = this.$refs?.DimBackInput?.$el?.querySelector("input");
    let inputDimLeft = this.$refs?.DimLeftInput?.$el?.querySelector("input");
    let inputDimRight = this.$refs?.DimRightInput?.$el?.querySelector("input");
    let inputDimTop = this.$refs?.DimToptInput?.$el?.querySelector("input");
    
  
    if(this.measureUnitDinSelected && this.measureUnitDinSelected!=''){
      let unitMeasure = this.measureUnitDin?.filter((e) => { return  this.measureUnitDinSelected === e.UnitMeasureId });
      this.afterUnitDin !="" && this.Conversions( unitMeasure[0]?.UnitMeasureId, this.afterUnitDin, this.DimFront, inputDimFront );
      this.afterUnitDin !="" && this.Conversions( unitMeasure[0]?.UnitMeasureId, this.afterUnitDin, this.DimBack, inputDimBack );
      this.afterUnitDin !="" && this.Conversions( unitMeasure[0]?.UnitMeasureId, this.afterUnitDin, this.DimLeft, inputDimLeft );
      this.afterUnitDin !="" && this.Conversions( unitMeasure[0]?.UnitMeasureId, this.afterUnitDin, this.DimRight, inputDimRight );
      this.afterUnitDin !="" && this.Conversions( unitMeasure[0]?.UnitMeasureId, this.afterUnitDin, this.DimTop, inputDimTop );
      this.afterUnitDin = this.measureUnitDinSelected;
      this.DimFront = inputDimFront.value;
      this.DimBack = inputDimBack.value;
      this.DimLeft = inputDimLeft.value;
      this.DimRight = inputDimRight.value;
      this.DimTop = inputDimTop.value;
    }
    else
    {
      this.DimFront = 0 ;
      inputDimFront.value = 0;    
      this.DimBack = 0 ;
      inputDimBack.value = 0; 
      this.DimLeft = 0 ;
      inputDimLeft.value = 0; 
      this.DimRight = 0 ;
      inputDimRight.value = 0; 
      this.DimTop = 0 ;
      inputDimTop.value = 0; 
      this.measureUnitDinSelected = '';
      this.afterUnitDin = this.measureUnitDinSelected;
    }
  }
  
  async function filterRf(event) {
    this.measureUnitRfSelected = event 
    let MaximumtInput = this.$refs?.MaximumtInput?.$el?.querySelector("input");
    let MinimumInput = this.$refs?.MinimumInput?.$el?.querySelector("input");
  
    if(this.measureUnitRfSelected && this.measureUnitRfSelected!=''){
      let unitMeasure = this.measureUnitRf?.filter((e) => { return  this.measureUnitRfSelected === e.UnitMeasureId });
      this.afterUnitRf !="" && await this.conversionsRef( unitMeasure[0]?.UnitMeasureId, this.afterUnitRf );
      this.afterUnitRf = this.measureUnitRfSelected;
    }
    else
    {
      this.minimunRangeRf = 0;
      MinimumInput.value = 0;
      this.maximunRangeRf = 0;
      MaximumtInput.value = 0;
      this.measureUnitRfSelected = '';
      this.afterUnitRf = this.measureUnitRfSelected;
    }
  }
  
  function Reset() {
    this.isSubmit = false;
    this.tabinfoIndex = 0;
    //general info
    this.conditionSelected = '';
    this.optionCondition = [];
    this.containerClassSelected = '';
    this.optionContainerClass = [];
    this.loadPort = '';
    this.loadPortSelected = '';
    this.dischargePort = '';
    this.dischargePortSelected = '';
    this.deliveryPort = '';
    this.deliveryPortSelected = '';
    this.cargoOriginPort = '';
    this.cargoOriginPortSelected = '';
    this.optionMasterPort = [];
    this.optionPort = [];
    //ISO AND SEALS
    this.DescriptionType = '';
    this.unitMeasureLengthDim = [];
    this.length = 0;
    this.lengthSelected = '';
    this.afterUnitLength = '';
    this.unitMeasureHeightDim = [];
    this.heigth = 0;
    this.heigthSelected = '';
    this.afterUnitHeigth = '';
    this.UnitMeasureWeigth = [];
    this.weight = 0;
    this.weightSelected = '';
    this.afterUnitWeight = '';
    this.UnitMeasureVgm = [];
    this.vgm = 0;
    this.vgmSelected = '';
    this.afterUnitVgm = '';
    this.maxGrossWeigthJson = [];
    this.weigthEmptyJson = [];
    this.maxLoadJson = [];
    //SPECIAL
    this.tpPowerPlug = [];
    this.tpPowerPlugSelected = '';
    this.dryReefer = false;
    this.plugged = false;
    this.measureUnitRf = [];
    this.measureUnitRfSelected = '';
    this.afterUnitRf = '';
    this.reeferSetting = '';
    this.minimunRangeRf = 0;
    this.maximunRangeRf = 0;
    this.DimBack = 0;
    this.DimFront = 0;
    this.DimLeft = 0;
    this.DimRight = 0;
    this.DimTop = 0;
    this.measureUnitDin = [];
    this.measureUnitDinSelected = '';
    this.afterUnitDin = '';
    //ULTIMA TRANSACCION
    this.VisitVoyage = '';
    this.VisitBranch = '';
    this.AtaAtd = '';
    this.VisitConfirmationDate = '';
    this.YardBranch = '';
    this.YardEntry = '';
    this.YardName = '';
    this.YardDispatch = '';
    this.$v.$reset();
  }
  
  //Computeds:
  function ConditionOptions() {
    if(this.optionCondition?.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      let chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.optionCondition?.map(function(e){
        chart.push({
          value: e.TpCargoConditionId,
          label: e.TpCargoConditionName,
        })
      })
      return chart;
    }
  }
  
  function ContainerClassOptions() {
    if(this.optionContainerClass?.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      let chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.optionContainerClass?.map(function(e){
        chart.push({
          value: e.TpCargoClassId,
          label: e.TpCargoClassCode,
        })
      })
      return chart;
    }
  }
  
  function ContainerMasterPort() {
    if(this.optionMasterPort?.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      let chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.optionMasterPort?.map(function(e){
        chart.push({
          value: e.PortId,
          label: e.PortCode
        })
      })
      return chart;
    }
  }
  
  function ContainerPort() {
    if(this.optionPort?.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      let chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.optionPort?.map(function(e){
        chart.push({
          value: e.PortId,
          label: e.PortCode
        })
      })
      return chart;
    }
  }
  
  //ISO AND SEALS
  function UnitMeasureDimrLengthOptions() {
    if(this.unitMeasureLengthDim && (this.unitMeasureLengthDim?.length === 0)){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      let chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.unitMeasureLengthDim?.map(function(e){
        chart.push({
          value: e.UnitMeasureId,
          label: e.UnitMeasureAbbrev,
        })
      })
      return chart;
    }
  }
  
  function UnitMeasureDimrHeightOptions() {
    if(this.unitMeasureHeightDim && (this.unitMeasureHeightDim?.length === 0)){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      let chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.unitMeasureHeightDim?.map(function(e){
        chart.push({
          value: e.UnitMeasureId,
          label: e.UnitMeasureAbbrev,
        })
      })
      return chart;
    }
  }
  function UnitMeasureWeigthOptions() {
    if(this.UnitMeasureWeigth && (this.UnitMeasureWeigth?.length === 0)){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      let chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.UnitMeasureWeigth?.map(function(e){
        chart.push({
          value: e.UnitMeasureId,
          label: e.UnitMeasureAbbrev,
        })
      })
      return chart;
    }
  }
  
  function UnitMeasureVgmOptions() {
    if(this.UnitMeasureVgm && (this.UnitMeasureVgm?.length === 0)){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      let chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.UnitMeasureVgm?.map(function(e){
        chart.push({
          value: e.UnitMeasureId,
          label: e.UnitMeasureAbbrev,
        })
      })
      return chart;
    }
  }
  
  function UnitMeasureUnitRfOptions() {
    if(this.measureUnitRf && (this.measureUnitRf?.length === 0)){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      let chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.measureUnitRf?.map(function(e){
        chart.push({
          value: e.UnitMeasureId,
          label: e.UnitMeasureAbbrev,
        })
      })
      return chart;
    }
  }
  
  function UnitMeasureUnitDinOptions() {
    if(this.measureUnitDin && (this.measureUnitDin?.length === 0)){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      let chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.measureUnitDin?.map(function(e){
        chart.push({
          value: e.UnitMeasureId,
          label: e.UnitMeasureAbbrev,
        })
      })
      return chart;
    }
  }
  
  function TpPowerPlugOptions() {
    if(this.tpPowerPlug && (this.tpPowerPlug?.length === 0)){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      let _lang = this.$i18n.locale;
      let chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.tpPowerPlug?.map(function(e){
        chart.push({
          value: e.TpPowerPlugId,
          label: _lang=='en'? e.TpPowerPlugNameEn : e.TpPowerPlugNameEs,
        })
      })
      return chart;
    }
  }
  
  async function Conversions(newValue, OldValue, Value, input, conversions = true){
    try{
        this.$store.state.yardManagement.loading = true;
        let conversion = conversions ? Number.parseFloat(Value?.replace(/[.]/g, '')?.replace(',', '.'))?.toFixed(2) : Value;
  
        await this.$http.ejecutar('GET', 'UnitMeasureConversion', { UnitMeasureToId:newValue, UnitMeasureFromId:OldValue, Value: conversion }, '').then(response => {
            if(response.status === 200){
              let  result = conversions ? NumberFormater.formatNumber(response.data.data[0].Value , 2) : response.data.data[0].Value;
              input.value =  result ?? 0.00;
            }
        }).catch(err => {
          this.$store.commit('yard-management/container/general-info/UnitMeasureConversion', err);
        })
    } catch (error) {
        this.$store.commit('yard-management/container/general-info/UnitMeasureConversion', error);
    }
    this.$store.state.yardManagement.loading = false;
  }
  
  async function conversionsRef(newValue, OldValue){
    try{
        let MaximumtInput = this.$refs?.MaximumtInput?.$el?.querySelector("input");
        let MinimumInput = this.$refs?.MinimumInput?.$el?.querySelector("input");
  
        this.$store.state.yardManagement.loading = true;
  
        await this.$http.ejecutar('GET', 'UnitMeasureConversion', { UnitMeasureToId:newValue, UnitMeasureFromId:OldValue, Value: Number.parseFloat(this.minimunRangeRf).toFixed(2) }, '').then(response => {
            if(response.status === 200){
              let  result = response.data.data[0].Value;
              MinimumInput.value =  result ?? 0.00;
              this.minimunRangeRf = result ?? 0.00;
            }
        }).catch(err => {this.$store.commit('yard-management/container/general-info/UnitMeasureConversion', err);})
        
  
        await this.$http.ejecutar('GET', 'UnitMeasureConversion', { UnitMeasureToId:newValue, UnitMeasureFromId:OldValue, Value: Number.parseFloat(this.maximunRangeRf).toFixed(2) }, '').then(response => {
            if(response.status === 200){
              let  result = response.data.data[0].Value;
              MaximumtInput.value =  result ?? 0.00;
              this.maximunRangeRf = result ?? 0.00;
            }
        }).catch(err => {this.$store.commit('yard-management/container/general-info/UnitMeasureConversion', err);})
    } catch (error) {
        this.$store.commit('yard-management/container/general-info/UnitMeasureConversion', error);
    }
    this.$store.state.yardManagement.loading = false;
  }
  
  function ValidateTpCargoStatus() {
    return process.env.VUE_APP_TP_CARGO_STATUS_EMPTY == this.NewEntryForm?.Status?.toUpperCase();
  }
  
  function limitWeight(){
      let value = 0.00;
      let unitMeasure = [];
      if (this.ValidateTpCargoStatus) {
        unitMeasure = this.weigthEmptyJson?.filter((e) => { return  this.weightSelected === e.UnitMeasureId });
        if(unitMeasure.length > 0){
            value = Number.parseFloat(unitMeasure[0].Value).toFixed(2);
        }else{
            value = 0.00;
        }
      } else {
        unitMeasure = this.maxGrossWeigthJson?.filter((e) => { return  this.weightSelected === e.UnitMeasureId });
        if(unitMeasure.length > 0){
            value = Number.parseFloat(unitMeasure[0].Value).toFixed(2);
        }else{
            value = 0.00;
        }
      }
      return value;
  }
  
  //ESTA CONDICION SOLO SE DA SI EL CONTENEDOR ESTA FULL
  function limitVgm(){
      let value = 0.00;
      let unitMeasure = [];
      if(!this.ValidateTpCargoStatus){
  
        unitMeasure = this.maxLoadJson?.filter((e) => { return  this.vgmSelected === e.UnitMeasureId });
        this.maxLoadJson
        if(unitMeasure.length > 0){
            value = Number.parseFloat(unitMeasure[0].Value).toFixed(2);
        }else{
            value = 0.00;
        }
      }
      else 
        return null
      return value;
  }
  
  function maxWeigthNumber(){
      let unitMeasure = this.UnitMeasureWeigth?.filter((e) => { return  this.weightSelected === e.UnitMeasureId });
      return  `${this.limitWeight} ${unitMeasure[0]?.UnitMeasureAbbrev ?? this.UnitMeasureWeigth[0]?.UnitMeasureAbbrev}`
  }
  
  function maxVgmNumber(){
     let unitMeasure = this.UnitMeasureVgm?.filter((e) => { return  this.vgmSelected === e.UnitMeasureId });
     return  `${this.limitVgm} ${unitMeasure[0]?.UnitMeasureAbbrev ?? this.UnitMeasureVgm[0]?.UnitMeasureAbbrev}`
  }

  function VisitMovement() {
    let _lang = this.$i18n.locale;
    let LastVisitJson = this.NewEntryForm?.LastVisitJson&&this.NewEntryForm?.LastVisitJson[0] ? this.NewEntryForm?.LastVisitJson[0] : {};
    return _lang=='en' ? LastVisitJson?.MovStowageNameEn||'' : LastVisitJson?.MovStowageNameEs||'';
  }

  function DaysInYard() {
    let _lang = this.$i18n.locale;
    let LastYardJson = this.NewEntryForm?.LastYardJson&&this.NewEntryForm?.LastYardJson[0] ? this.NewEntryForm?.LastYardJson[0] : {};
    return _lang=='en' ? LastYardJson?.DaysInYardEn||'' : LastYardJson?.DaysInYardEs||'';
  }
  
  export default {
    name: 'new-entry-step-two',
    data,
    mixins: [GeneralMixin, ReportesVisitas, ModalMixin],
    props: { 
      active: Boolean,
      SearchContainer: Boolean,
      NewEntryForm: Object, 
      NewGeneralInfo: {
        type: Array,
        default: () => [],
      } 
    },
    components: {
    },
    directives: {
      money: VMoney
    },
    validations(){
      return StepTwo(
        this.NewEntryForm.FgShipperOwned,
        this.NewEntryForm.PortActivity,
        this.limitWeight,
        this.limitVgm,
        !this.ValidateTpCargoStatus,
        this.plugged,
        this.dryReefer, 
        this.FgApplyOversize,
        {DimBack : this.DimBack, DimLeft: this.DimLeft, DimRight: this.DimRight, DimTop: this.DimTop, DimFront: this.DimFront},
      );
    },
    directives: UpperCase,
    methods: {
      Submit,
      Conversions,
      handleTab,
      filterLoadPort,
      filterDischargePort,
      filterTranshipmentPort,
      filterDeliveryPort,
      filterCargoOriginPort,
      filterLength,
      filterHeigth,
      filterWeight,
      filterVgm,
      filterRf,
      filterMeasureDin,
      Reset,
      conversionsRef,
    }, 
    computed: {
      ConditionOptions,
      ContainerMasterPort,
      ContainerPort,
      ContainerClassOptions,
      UnitMeasureDimrLengthOptions,
      UnitMeasureDimrHeightOptions,
      UnitMeasureWeigthOptions,
      UnitMeasureVgmOptions,
      UnitMeasureUnitDinOptions,
      UnitMeasureUnitRfOptions,
      TpPowerPlugOptions,
      limitWeight,
      limitVgm,
      maxWeigthNumber,
      maxVgmNumber,
      ValidateTpCargoStatus,
      VisitMovement,
      DaysInYard,
      ...mapState({
        branch: state => state.auth.branch,
        yardData: state => state.yardManagement.yardData,
        dataContainer: state => state.yardManagement.dataContainer,
      })
    },
    watch: {
      active: function (NewVal) {
        if (NewVal&&this.NewGeneralInfo.length != 0) {
          //GENERAL INFO CONTAINER
          let StatusData = this.NewEntryForm.ContainerStatus.find(item => item.TpCargoStatusId == this.NewEntryForm.Status);
          this.optionCondition = StatusData?.TpCargoConditionList ?? [];
          this.optionContainerClass = this.NewGeneralInfo[0]?.TpCargoClassJson ?? [];
          this.containerClassSelected = this.NewEntryForm.TpCargoClassId ?? '';
          this.conditionSelected = this.optionCondition.length==1? this.optionCondition[0].TpCargoConditionId : this.NewEntryForm.TpCargoConditionId ?? '';

            //PORT
          this.optionPort = this.NewGeneralInfo[0]?.PortJson ?? [];
          this.optionMasterPort = this.NewEntryForm?.Itinerary ? this.NewGeneralInfo[0]?.ServicePortMasterJson ?? [] : this.NewGeneralInfo[0]?.PortJson ?? [];
          this.loadPortSelected = this.NewEntryForm.LoadPortId ?? '';
          this.filterLoadPort(this.loadPortSelected);
          this.dischargePortSelected = this.NewEntryForm.DischargePortId ?? '';
          this.filterDischargePort(this.dischargePortSelected);
          this.deliveryPortSelected = this.NewEntryForm.DeliveryPortId ?? '';
          this.filterDeliveryPort(this.deliveryPortSelected);
          this.cargoOriginPortSelected = this.NewEntryForm.OriginalPortLoadingId ?? '';
          this.filterCargoOriginPort(this.cargoOriginPortSelected)
          this.TransshipmentPortSelected = this.NewEntryForm.TranshipmentPortId ?? '';
          this.filterTranshipmentPort(this.TransshipmentPortSelected)

          //ISO AND SEALS
          let IsoData = this.NewEntryForm.IsoList;
          this.DescriptionType = this.NewEntryForm.DescriptionType ?? (IsoData?.TpCargoDetailName || '');
          this.maxGrossWeigthJson = IsoData?.MaxGrossWeigthJson;
          this.weigthEmptyJson = IsoData?.WeigthEmptyJson;
          this.maxLoadJson = IsoData?.MaxLoadJson;
            //WEIGHT VALUE
          this.weightSelected = this.NewGeneralInfo[0]?.UnitMeasureWeigthJson[0]?.UnitMeasureId ?? '';
          this.UnitMeasureWeigth = this.NewGeneralInfo[0]?.UnitMeasureWeigthJson ?? [];
          if (this.SearchContainer) {
            this.weightSelected = this.NewEntryForm.UnitMeasureWeigth ?? '';
            let inputWeigth = this.$refs?.weigthInput?.$el?.querySelector("input");
            inputWeigth.value = Number.parseFloat(this.NewEntryForm.Weigth).toFixed(2) ?? 0.00;
          }else{
            let inputWeigth = this.$refs?.weigthInput?.$el?.querySelector("input");
            let WeigthValue = IsoData?.WeigthEmptyJson.find(item => item.UnitMeasureId == this.weightSelected)?.Value ?? 0;
            inputWeigth.value = Number.parseFloat(WeigthValue).toFixed(2) ?? 0.00;
          }
          this.afterUnitWeight = this.weightSelected;
  
            //LENGHT VALUE
          this.lengthSelected = this.NewGeneralInfo[0]?.UnitMeasureDimJson[0]?.UnitMeasureId ?? '';
          this.afterUnitLength = this.lengthSelected;
          this.unitMeasureLengthDim = this.NewGeneralInfo[0]?.UnitMeasureDimJson ?? [];
          let lenghtInput = this.$refs?.lenghtInput?.$el?.querySelector("input");
          let LenghtValue = IsoData?.LenghtJson.find(item => item.UnitMeasureId == this.lengthSelected)?.Value ?? 0;
          lenghtInput.value = Number.parseFloat(LenghtValue).toFixed(2) ?? 0.00;
  
            //HEIGHT VALUE
          this.heigthSelected = this.NewGeneralInfo[0]?.UnitMeasureDimJson[0]?.UnitMeasureId ?? '';
          this.afterUnitHeigth = this.heigthSelected;
          this.unitMeasureHeightDim = this.NewGeneralInfo[0]?.UnitMeasureDimJson ?? [];
          let heightInput = this.$refs?.heightInput?.$el?.querySelector("input");
          let HeigthValue = IsoData?.HeigthJson.find(item => item.UnitMeasureId == this.heigthSelected)?.Value ?? 0;
          heightInput.value = Number.parseFloat(HeigthValue).toFixed(2) ?? 0.00;
  
            //VGM VALUE
          this.vgmSelected = this.NewGeneralInfo[0]?.UnitMeasureWeigthJson[0]?.UnitMeasureId ?? '';
          this.UnitMeasureVgm = this.NewGeneralInfo[0]?.UnitMeasureWeigthJson ?? [];
          if (this.SearchContainer) {
            this.vgmSelected = this.NewEntryForm.UnitMeasureVgm ?? '';
            let inputVgm = this.$refs?.vgmInput?.$el?.querySelector("input");
            inputVgm.value = Number.parseFloat(this.NewEntryForm.Vgm).toFixed(2);
          } else {
            let inputVgm = this.$refs?.vgmInput?.$el?.querySelector("input");
            inputVgm.value = Number.parseFloat(0).toFixed(2);
          }
          this.afterUnitVgm = this.vgmSelected;
          
          //ESPECIAL
          this.FgApplyOversize = IsoData?.FgApplyOversize;
          this.plugged = false;
          if (this.FgApplyOversize) {
            //DINS
            this.measureUnitDin = this.NewGeneralInfo[0].UnitMeasureDimJson ?? [];
            this.afterUnitDin = this.measureUnitDin[0]?.UnitMeasureId ?? '';
            this.measureUnitDinSelected = this.measureUnitDin[0]?.UnitMeasureId ?? '';
          }
          if (this.SearchContainer) {
            this.dryReefer = this.NewEntryForm.DryReefer ?? false;
            this.measureUnitRfSelected = this.NewEntryForm.UnitMeasureRf ?? '';
            this.plugged = this.NewEntryForm.Plugged ?? false;
            this.tpPowerPlugSelected = this.NewEntryForm.TpPowerPlugId ?? '';
            this.reeferSetting = this.NewEntryForm.ReeferSetting ?? '';
            this.minimunRangeRf = this.NewEntryForm.MinimumRangeRf ?? 0;
            this.maximunRangeRf = this.NewEntryForm.MaximumRangeRf ?? 0;
            this.measureUnitDinSelected = this.NewEntryForm.UnitMeasureDim ?? '';
            this.DimFront = this.NewEntryForm.DimFront ?? '';
            this.DimBack = this.NewEntryForm.DimBack ?? '';
            this.DimLeft = this.NewEntryForm.DimLeft ?? '';
            this.DimRight = this.NewEntryForm.DimRight ?? '';
            this.DimTop = this.NewEntryForm.DimTop ?? '';
            this.$v.$touch();
          }else{
            this.dryReefer = IsoData?.FgApplyRefrigeration;
          }
          if (this.dryReefer) {
            //RF
            this.measureUnitRf = this.NewGeneralInfo[0]?.UnitMeasureTemperatureJson ?? [];
            this.afterUnitRf = this.measureUnitRf[0]?.UnitMeasureId ?? '';
            this.measureUnitRfSelected = this.measureUnitRf[0]?.UnitMeasureId ?? '';
            //TP POWER PLUG
            this.tpPowerPlug = this.NewGeneralInfo[0]?.TpPowerPlugJson ?? [];
            this.tpPowerPlugSelected = this.this.NewGeneralInfo[0].TpPowerPlugId ?? '';
          }
          //ULTIMA TRANSACCION
            //VISITA
          let LastVisitJson = this.NewEntryForm?.LastVisitJson&&this.NewEntryForm?.LastVisitJson[0] ? this.NewEntryForm?.LastVisitJson[0] : {};
          this.VisitVoyage = LastVisitJson?.VesselId ? `${LastVisitJson.VesselName} - ${LastVisitJson.Voyage}` : '';
          this.VisitBranch = LastVisitJson.LastVisitCompanyBranchName ?? '';
          this.AtaAtd = LastVisitJson.Ata ? `${LastVisitJson.Ata?DateFormater.formatDateTimeWithSlash(LastVisitJson.Ata):'N/A'} - ${LastVisitJson.Atd?DateFormater.formatDateTimeWithSlash(LastVisitJson.Atd):'N/A'}` : '';
          this.VisitConfirmationDate = LastVisitJson.ConfirmationDate ? DateFormater.formatDateTimeWithSlash(LastVisitJson.ConfirmationDate) : '';
            //PATIO
          let LastYardJson = this.NewEntryForm?.LastYardJson&&this.NewEntryForm?.LastYardJson[0] ? this.NewEntryForm?.LastYardJson[0] : {};
          this.YardBranch = LastYardJson.YardName ?? '';
          this.YardEntry = LastYardJson.ReceptionDate ? DateFormater.formatDateTimeWithSlash(LastYardJson.ReceptionDate) : '';
          this.YardName = LastYardJson.LastCompanyBranchYardEventName ?? '';
          this.YardDispatch = LastYardJson.DispatchDate ? DateFormater.formatDateTimeWithSlash(LastYardJson.DispatchDate) : '';
        }else {
          this.Reset();
        }
      },
      plugged: function(newVal) {
        if(!newVal) this.tpPowerPlugSelected = "";
      },
    },
  };
  </script>
  <style scope>
    .tamanio_auto {
      width: 100%; 
      overflow-x: auto;
    }
  
    .tabla_auto {
      width: 140%; 
    }
  
    .azulado {
      background-color: #7abcff;
      color: #FFF;
    }
  </style>
  