<template>
  <div class="mt-3">
    <CCollapse :show="!showBookingContainer">
      <CRow class="justify-content-center m-0">
        <CCol v-if="!fgInfo" sm="12">
          <CCol sm="12" class="mb-2">
            <label class="col-sm-12 col-lg-auto px-0 m-0 text-right">
              <b>{{$t('label.packaging')}}</b>
            </label>
          </CCol>
          <CRow class="m-0">
            <CCol sm="12" lg="3">
              <div class="form-group form-row">
                <label class="text-right col-sm-12 col-lg-6 col-form-label-sm mb-0">{{`${$t('label.quantity')} ${$t('label.AbbreviatedPlanned')}`}}</label>
                <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                  <money
                    size="sm"
                    v-bind="QuantityMeasure" 
                    class="form-control"
                    v-model="Packaging.Quantity"
                    disabled
                  >
                  </money>
                </div>
              </div>
            </CCol>
            <CCol sm="12" lg="3">
              <div class="form-group form-row">
                <label class="text-right col-sm-12 col-lg-6 col-form-label-sm mb-0">{{`${$t('label.quantity')} ${$t('label.AbbreviatedReception')}`}}</label>
                <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                  <money
                    size="sm"
                    v-bind="QuantityMeasure" 
                    class="form-control"
                    v-model="Packaging.QuantityReceived"
                    addLabelClasses="text-right"
                    disabled
                  >
                  </money>
                </div>
              </div>
            </CCol>
            <CCol sm="12" lg="3">
              <div class="form-group form-row">
                <label class="text-right col-sm-12 col-lg-6 col-form-label-sm mb-0">{{`${$t('label.Serialized')}`}}</label>
                <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                  <money
                    size="sm"
                    v-bind="measure" 
                    class="form-control"
                    v-model="Packaging.SerializedQuantity"
                    disabled
                  >
                  </money>
                </div>
              </div>
            </CCol>
            <CCol sm="12" lg="3">
              <div class="form-group form-row">
                <label class="text-right col-sm-12 col-lg-6 col-form-label-sm mb-0">{{`${$t('label.weight')} (KGM)`}}</label>
                <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                  <money
                    size="sm"
                    v-bind="QuantityMeasure" 
                    class="form-control"
                    v-model="Packaging.Weight"
                    disabled
                  >
                  </money>
                </div>
              </div>
            </CCol>
            <CCol sm="12" lg="3">
              <div class="form-group form-row">
                <label class="text-right col-sm-12 col-lg-6 col-form-label-sm mb-0">{{`${$t('label.volume')} (M³)`}}</label>
                <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                  <money
                    size="sm"
                    v-bind="measure" 
                    class="form-control"
                    v-model="Packaging.Volumen"
                    disabled
                  >
                  </money>
                </div>
              </div>
            </CCol>
          </CRow>
        </CCol>

        <CCol v-if="!fgInfo" sm="12">
          <CTabs :active-tab="TabIndex" @update:activeTab="TabIndex=$event" variant="tabs" class="m-0">
            <CTab :title="$t('label.generalInfo')" :class="vForm.serialization.$anyError ? 'tab-error' : ''">
              <div class="border border-top-0 rounded-bottom pt-3 m-0">
                <CRow class="m-0">
                  <CCol sm="12" class="px-2">
                    <div class="form-group form-row">
                      <label class="text-right col-sm-12 col-lg-auto col-form-label-sm mb-0">{{`${$t('label.automaticCalculation')} (M³)`}}</label>
                      <div class="col-sm-12 col-lg-auto d-flex justify-content-end">
                        <CSwitch
                          color="watch"
                          variant="3d"
                          type="checkbox"
                          :checked.sync="FgAutomatic"
                          @update:checked="form.serialization.Length = 0, form.serialization.Width = 0, form.serialization.Height = 0, form.serialization.Volumen = 0"
                        />
                      </div>
                    </div>
                  </CCol>
                  <CCol sm="12" lg="3" class="px-2">
                    <CSelect
                      size="sm"
                      v-uppercase
                      :label="$t('label.packaging')"
                      :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                      :options="PackagingOptions"
                      addLabelClasses="required text-right"
                      v-model="form.serialization.PackagingIdX"
                      :is-valid="hasError(vForm.serialization.PackagingId)"
                      :invalid-feedback="errorMessage(vForm.serialization.PackagingId)" 
                      @change="onChagePackagingId($event.target.value)"
                    />
                  </CCol>
                  <CCol sm="12" lg="3" class="px-2">
                    <CInput
                      v-uppercase
                      size="sm"
                      :placeholder="$t('label.commodity')"
                      addLabelClasses="text-right"
                      :label="$t('label.commodity')"
                      :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
                      v-model="Packaging.CommodityName"
                      disabled
                    />
                  </CCol>
                  <CCol sm="12" lg="3" class="px-2">
                    <CInput
                      v-uppercase
                      size="sm"
                      :placeholder="$t('label.serial')"
                      addLabelClasses="text-right required"
                      :label="$t('label.serial')"
                      :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
                      v-model="vForm.serialization.Serial.$model"
                      :is-valid="hasError(vForm.serialization.Serial)"
                      :invalid-feedback="errorMessage(vForm.serialization.Serial)"
                    >
                      <template #append>
                        <CButton
                          v-if="validateBooking && ValidatePackagingContainer(form.serialization.PackagingId)"
                          size="sm"
                          class="btn-light-watch"
                          style="padding: 0.15rem 0.4rem; border-radius: 0.2rem"
                          @click="ContainerSearch"
                        >
                          <CIcon name="cil-search"/>
                        </CButton>
                      </template>
                    </CInput>
                  </CCol>
                  <CCol v-if="validateBooking && ValidatePackagingContainer(form.serialization.PackagingId)" sm="12" lg="3" class="px-2">
                    <CInput
                      v-uppercase
                      size="sm"
                      placeholder="ISO"
                      addLabelClasses="text-right required"
                      label="ISO"
                      :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
                      v-model="form.serialization.IsoCode"
                      :is-valid="hasError(vForm.serialization.CargoId)"
                      :invalid-feedback="errorMessage(vForm.serialization.CargoId)"
                      disabled
                    />
                  </CCol>
                  <CCol sm="12" lg="3" class="px-2">
                    <div class="form-group form-row">
                      <label class="text-right col-sm-12 col-lg-5 required col-form-label-sm mb-0">{{`${$t('label.weight')} (KGM)`}}</label>
                      <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                        <money
                          size="sm"
                          v-bind="QuantityMeasure" 
                          :class="!vForm.serialization.Weight.$dirty ? 'form-control' : (vForm.serialization.Weight.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                          v-model="vForm.serialization.Weight.$model"
                        >
                        </money>
                        <div class="invalid-feedback" v-if="vForm.serialization.Weight.$error&&!CleanInformation">
                          {{ errorMessage(vForm.serialization.Weight) }}
                        </div>
                      </div>
                    </div>
                  </CCol>
                  <CCol sm="12" lg="3" class="px-2">
                    <div class="form-group form-row">
                      <label class="text-right col-sm-12 col-lg-5 col-form-label-sm mb-0">{{`${$t('label.volume')} (M³)`}}</label>
                      <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                        <money
                          v-if="FgAutomatic"
                          size="sm"
                          v-bind="measure" 
                          :class="!vForm.serialization.Volumen.$dirty ? 'form-control' : (vForm.serialization.Volumen.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                          v-model="vForm.serialization.Volumen"
                          disabled
                        >
                        </money>
                        <money
                          v-else
                          size="sm"
                          v-bind="measure" 
                          :class="!vForm.serialization.Volumen.$dirty ? 'form-control' : (vForm.serialization.Volumen.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                          v-model="vForm.serialization.Volumen.$model"
                        >
                        </money>
                        <div class="invalid-feedback" v-if="vForm.serialization.Volumen.$error&&!CleanInformation">
                          {{ errorMessage(vForm.serialization.Volumen) }}
                        </div>
                      </div>
                    </div>
                  </CCol>
                  <CCol sm="12" lg="3" class="px-2">
                    <div class="form-group form-row">
                      <label class="text-right col-sm-12 col-lg-4 col-form-label-sm">{{`${$t('label.length')} (M)`}}</label>
                      <div class="input-group col-sm-12 col-lg-8 input-group-sm">
                        <money
                          size="sm"
                          v-bind="measure" 
                          :class="!vForm.serialization.Length.$dirty ? 'form-control' : (vForm.serialization.Length.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                          v-model="vForm.serialization.Length.$model"
                          addLabelClasses="text-right"
                          :disabled="!FgAutomatic"
                        >
                        </money>
                        <div class="invalid-feedback" v-if="vForm.serialization.Length.$error&&!CleanInformation">
                          {{ errorMessage(vForm.serialization.Length) }}
                        </div>
                      </div>
                    </div>
                  </CCol>
                  <CCol sm="12" lg="3" class="px-2">
                    <div class="form-group form-row">
                      <label class="text-right col-sm-12 col-lg-4 col-form-label-sm">{{`${$t('label.width')} (M)`}}</label>
                      <div class="input-group col-sm-12 col-lg-8 input-group-sm">
                        <money
                          size="sm"
                          v-bind="measure" 
                          :class="!vForm.serialization.Width.$dirty ? 'form-control' : (vForm.serialization.Width.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                          v-model="vForm.serialization.Width.$model"
                          addLabelClasses="text-right"
                          :disabled="!FgAutomatic"
                        >
                        </money>
                        <div class="invalid-feedback" v-if="vForm.serialization.Width.$error&&!CleanInformation">
                          {{ errorMessage(vForm.serialization.Width) }}
                        </div>
                      </div>
                    </div>
                  </CCol>
                  <CCol sm="12" lg="3" class="px-2">
                    <div class="form-group form-row">
                      <label class="text-right col-sm-12 col-lg-4 col-form-label-sm">{{`${$t('label.height')} (M)`}}</label>
                      <div class="input-group col-sm-12 col-lg-8 input-group-sm">
                        <money
                          size="sm"
                          v-bind="measure" 
                          :class="!vForm.serialization.Height.$dirty ? 'form-control' : (vForm.serialization.Height.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                          v-model="vForm.serialization.Height.$model"
                          addLabelClasses="text-right"
                          :disabled="!FgAutomatic"
                        >
                        </money>
                        <div class="invalid-feedback" v-if="vForm.serialization.Height.$error&&!CleanInformation">
                          {{ errorMessage(vForm.serialization.Height) }}
                        </div>
                      </div>
                    </div>
                  </CCol>
                  <CCol sm="12" lg="3" class="px-2">
                    <CTextarea
                      :label="$t('label.description')"
                      :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                      size="sm"
                      v-uppercase
                      v-model.trim="vForm.serialization.DescriptionOfGoods.$model"
                      addLabelClasses="d-flex text-right"
                      rows="2"
                      :is-valid="hasError(vForm.serialization.DescriptionOfGoods)"
                      :invalid-feedback="errorMessage(vForm.serialization.DescriptionOfGoods)"
                    />
                  </CCol>
                </CRow>
              </div>
            </CTab>
            <CTab :title="$t('label.imdg')">
              <div class="border border-top-0 rounded-bottom pt-3 m-0">
                <ImdgTab
                  :form="form"
                  :imdgItems="imdgItems"
                  :Tab="Tab"
                  :fgPackagingTab="true"
                  :Clean="CleanInformation"
                  @deleteImdg="(event) => deleteImdg(event)"
                  @imdgUpdateItems="(event)=>{submitImdg(event)} "
                />
              </div>
            </CTab>
            <CTab :title="$t('label.accesories')">
              <div class="border border-top-0 rounded-bottom pt-3 m-0">
                <AccesoryTab
                  :form="form"
                  :vForm="vForm"
                  :AccesoryList="AccesoryList"
                  :AccesoryJson="form.serialization.AccesoryJson"
                  :Clean="CleanInformation"
                  @deleteAccesory="(event) => deleteAccesory(event)"
                  @updateAccesory="(event)=> submitAccesory(event)"
                />
              </div>
            </CTab>
          </CTabs>
          <CCol sm="12 px-0">
            <div class="col-sm-12 col-form-label-sm text-right px-0" style="text-align: left;">
              <CButton
                color="add"
                size="sm"
                class="mr-1"
                v-c-tooltip="{content: $t('label.add'), placement: 'top-end'}" 
                :disabled="isSubmit"
                @click="SubmitSerialization"
              >
                <CIcon name="checkAlt"/>
              </CButton>
              <CButton
                color="wipe"
                class="justify-content-end"
                size="sm"
                v-c-tooltip="{content: $t('label.cleanInformation'), placement: 'top-end'}" 
                @click="Clear(false)"
              >
                <CIcon name="cil-brush-alt" />
              </CButton>
            </div>
          </CCol>
        </CCol>

        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            :items="computedSerializationList"
            :fields="serializationfields"
            :items-per-page="5"
            column-filter
            pagination
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            sorter
          >
            <template #Status="{ item }">
              <td class="text-center">
                <CBadge :color="getBadge(item.Status)">
                  {{ $t('label.'+item.Status) }}
                </CBadge>
              </td>
            </template>
            <template #Serial="{ item }">
              <td class="text-center">
                {{ `${item.Serial} ${item?.TpCargoDetailCode ? `(${item.TpCargoDetailCode ?? ''})` : ''}`.trim() }}
              </td>
            </template>
            <template #Quantity="{ item }">
              <td class="text-center">
                {{ formatNumber(item.Quantity, 2) }}
              </td>
            </template>
            <template #QuantityReceived="{ item }">
              <td class="text-center">
                {{ formatNumber(item.QuantityReceived, 2) }}
              </td>
            </template>
            <template #QuantityDispatched="{ item }">
              <td class="text-center">
                {{ formatNumber(item.QuantityDispatched, 2) }}
              </td>
            </template>
            <template #Weight="{ item }">
              <td class="text-center">
                {{ formatNumber(item.Weight, 2) }}
              </td>
            </template>
            <template #WeigthReception="{ item }">
              <td class="text-center">
                {{ formatNumber(item.WeigthReception, 2) }}
              </td>
            </template>
            <template #WeigthDispatch="{ item }">
              <td class="text-center">
                {{ formatNumber(item.WeigthDispatch, 2) }}
              </td>
            </template>
            <template #Volumen="{ item }">
              <td class="text-center">
                {{ formatNumber(item.Volumen, 2) }}
              </td>
            </template>
            <template #VolumenReception="{ item }">
              <td class="text-center">
                {{ formatNumber(item.VolumenReception, 2) }}
              </td>
            </template>
            <template #VolumenDispatch="{ item }">
              <td class="text-center">
                {{ formatNumber(item.VolumenDispatch, 2) }}
              </td>
            </template>
            <template #options="{ item }">
              <td class="text-center">
                <CButton 
                  v-if="!validateBooking ? !ValidatePackagingContainer(item.PackagingId) && !fgInfo : !fgInfo"
                  color="edit"
                  size="sm"
                  class="mr-1"
                  v-c-tooltip="{
                    content: $t('label.edit')+' '+$t('label.serial'),
                    placement: 'top-start'
                  }"
                  @click="EditSerialization(item)"
                >
                  <CIcon name="pencil" />
                </CButton>
                <CButton 
                  v-if="validateBooking && ValidatePackagingContainer(item.PackagingId) && !fgInfo"
                  color="watch"
                  size="sm"
                  class="mr-1"
                  v-c-tooltip="{
                    content: $t('label.edit')+' '+$t('label.container'),
                    placement: 'top-start'
                  }"
                  @click="EditContainer(item)"
                >
                  <CIcon name='cil-list' />
                </CButton>
                <CButton
                  v-if="!validateBooking ? !ValidatePackagingContainer(item.PackagingId) && !fgInfo : !fgInfo"
                  class="btn btn-sm btn-wipe"
                  v-c-tooltip="{
                    content: $t('label.delete')+$t('label.serial'),
                    placement: 'top-start',
                  }"
                  @click="DeletePackaging(item)"
                >
                  <CIcon name="cil-trash" />
                </CButton>
              </td>
            </template>
          </dataTableExtended>
          <div class="d-flex justify-content-end text-right fw-bold">
            {{ `${$t('label.totalQuantity')}: ${formatNumber(form.SerializationList.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Quantity ? currentValue.Quantity : 0), 0), 2)}` }} -
            {{ `${$t('label.totalWeight')}: ${formatNumber(form.SerializationList.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Weight ? currentValue.Weight : 0), 0), 2)}` }} -
            {{ `${$t('label.TotalVolume')}: ${formatNumber(form.SerializationList.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Volumen ? currentValue.Volumen : 0), 0), 2)}` }}
          </div>
          <div class="text-invalid-feedback text-center" v-if="!fgInfo && vForm.SerializationList.$error">
            {{ $t('validation.RequiredDetail') }}
          </div>
        </CCol>
      </CRow>
    </CCollapse>
    <CCollapse v-if="validateBooking" :show="showBookingContainer">
      <BookingContainer
        :form="form"
        :SerialItem="SerialItem"
        :isEdit="Boolean(SerialItem?.isEdit)"
        :isList="Boolean(SerialItem?.isList)"
        :Active="showBookingContainer"
        @changeContainer="changeContainer"
        @updateContainer="updateContainer"
        @Close="showBookingContainer=false, SerialItem={}"
      />
    </CCollapse>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import ImdgTab from '@/pages/yard-management/container/bl/tabs/imdg-tab';
import AccesoryTab from './tabs/accesory-tab';
import BookingContainer from './bookingContainer/index';
import { Money } from "v-money";
import { NumberFormater } from "@/_helpers/funciones";

function data() {
  return {
    TabIndex: 0,
    showBookingContainer: false,
    FgAutomatic: false,
    CleanInformation: false,
    isSubmit: false,
    Packaging: {
      Weight: 0,
      Volumen: 0,
      Quantity: 0,
      QuantityReceived: 0,
      SerializedQuantity: 0,
      CommodityName: '',
    },
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
      maxlength: 18,
    },
    QuantityMeasure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 0,
      masked: false,
      maxlength: 6,
    },
    SerialItem: {},
    imdgItems: [],
    AccesoryList: [],
  };
}

//methods
function getYardCargoById(Container) {
  this.$store.state.yardManagement.loading = true;
  this.$http.get("YardCargo-by-Id", { YardCargoId: Container.YardCargoId, YardId: Container.YardId })
  .then(response => {
    let Container = response?.data?.data?.[0] ?? undefined;
    this.$store.state.yardManagement.dataContainer.ContainerTabIndex = 0;
    if (Container) {
      this.$store.state.yardManagement.dataContainer = Container;
    }else {
      this.$store.state.yardManagement.dataContainer = {};
    }
  }).catch( err => {
      this.$store.state.yardManagement.dataContainer = {};
      this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
      });
  })
  .finally(() => {
    this.$store.state.yardManagement.loading = false;
  });
}

async function getAccesoryJson() {
  this.$store.state.yardManagement.loading = true;
  await this.$http
  .get('BlCargoMasterDetailSerialPackagingAccesory-list',{BlCargoMasterDetailSerialId: '', PackagingId: this.form.serialization.PackagingId})
    .then(async response => {
      this.AccesoryList = response?.data?.data ?? [];
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).finally(() => {
      this.$store.state.yardManagement.loading = false;
    });
}

async function ContainerSearch() {
	try {
    this.form.serialization.CargoId = '';
    this.form.serialization.IsoCode = '';
		if (this.form.serialization.Serial.length < 1) {
			throw 'ERROR'
		}
		this.$store.state.yardManagement.loading = true;
		let CargoJson = {
      BlCargoId: this.form?.dataBasic?.BlCargoId ?? '',
			ContainerCode: this.form.serialization.Serial,
			CompanyBranchId: this.branch.CompanyBranchId,
		}
		this.$http.post('YardContainerSearch', CargoJson, { root: 'CargoJson' })
			.then(async response => {
				let List = response.data.data ?? [];
				if (List.length != 0 && Object.entries(List[0]).length > 1) {
          let CargoMasterJson = List[0]?.CargoMasterJson&&List[0]?.CargoMasterJson.length!=0 ? List[0]?.CargoMasterJson[0] : {};
          if (this.form.dataBasic.VisitIdOrItineraryId == CargoMasterJson.ItineraryId ?? '') {
            this.form.serialization.CargoId = CargoMasterJson?.CargoId ?? '';
            this.form.serialization.IsoCode = List?.[0]?.IsoCode ?? '';
            this.vForm.serialization.CargoId.$touch();
          }else {
            this.$notify({
              group: 'container',
              title: '¡Error!',
              text: 'ERROR',
              type: "error"
            });
          }
				} else {
          this.SerialItem = { ...this.form.serialization, Status: 1, isEdit: this.form.serialization.Id != '' }
					this.showBookingContainer = true;
				}
			})
			.catch(err => {
				this.$notify({
					group: 'container',
					title: '¡Error!',
					text: err,
					type: "error"
				});
			})
			.finally(() => {
				this.$store.state.yardManagement.loading = this.showBookingContainer ? true : false;
			});
	} catch (error) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: error,
      type: "error"
    });
		this.Reset(false);
		this.$v.ContainerCode.$touch();
	}
}

async function SubmitSerialization() {
  try {
    this.isSubmit = true;
    this.vForm.serialization.$touch();
    if (this.vForm.serialization.$error){
      throw this.$t('label.errorsPleaseCheck');
    }
    
    let PackagingData = this.PackagingOptions.find(item => item.value == this.form.serialization.PackagingIdX)

    if (!this.form.serialization.Id && (this.form.SerializationList.filter(item => item.PackagingIdX == this.form.serialization.PackagingIdX && item.Status == 1).length == PackagingData.Quantity)) {
      throw this.$t('label.PackagingIsFullySerialized');
    }

    if (this.form.SerializationList.some(item => item.PackagingId == this.form.serialization.PackagingId && item.Serial == this.form.serialization.Serial && item.IdX != this.form.serialization.Id && item.Status == 1)) {
      throw this.$t('label.ItemPreviouslyRegistered');
    }

    let Submit = {
      IdX: this.form.serialization.Id != '' ? this.form.serialization.Id : (this.form.SerializationList.length > 0 ? this.form.SerializationList[this.form.SerializationList.length - 1].IdX + 1 : 1),
      PackagingIdX: this.form.serialization.PackagingIdX ?? '',
      PackagingId: this.form.serialization.PackagingId ?? '',
      PackagingNameEs: PackagingData?.PackagingNameEs ?? '',
      PackagingNameEn: PackagingData?.PackagingNameEn ?? '',
      Serial: this.form.serialization.Serial ?? '',
      CargoId: this.form.serialization.CargoId ?? '',
      IsoCode: this.form.serialization.IsoCode ?? '',
      DescriptionOfGoods: this.form.serialization.DescriptionOfGoods ?? '',  
      Length: NumberFormater.setNum(this.form.serialization.Length),
      Width: NumberFormater.setNum(this.form.serialization.Width),
      Height: NumberFormater.setNum(this.form.serialization.Height),
      Weight: NumberFormater.setNum(this.form.serialization.Weight),
      Volumen: this.FgAutomatic ? NumberFormater.setNum(this.Meters.Volumen) : NumberFormater.setNum(this.form.serialization.Volumen),
      Quantity: 1,
      ImdgJson: this.imdgItems,
      AccesoryJson: this.form.serialization.AccesoryJson ?? [],
      Status: 1,
    }
    if (this.form.serialization.Id=='') {
      this.form.SerializationList.push(Submit);
    }else{
      this.form.SerializationList = this.form.SerializationList.map(item => {
        if (item.IdX == this.form.serialization.Id) {
          return {
            ...item,
            ...Submit,
          }
        }else{
          return item;
        }
      });
    }
    
    let accumulator = { Weight: 0, Volumen: 0 };
    this.form.SerializationList.map(item => {
      if (item?.Status != 0 && item.PackagingIdX == this.form.serialization.PackagingIdX) {
        accumulator.Weight += item.Weight;
        accumulator.Volumen += item.Volumen;
      }
    })
    this.form.PackagingList = this.form.PackagingList.map(item => {
      if (item.IdX == this.form.serialization.PackagingIdX && item.Status == 1) {
        return {
          ...item,
          Weight: PackagingData.Weight < accumulator.Weight ? accumulator.Weight : PackagingData.Weight,
          Volumen: PackagingData.Volumen < accumulator.Volumen ? accumulator.Volumen : PackagingData.Volumen,
        }
      }else{
        return item;
      }
    })

    await this.Clear(false);
    this.isSubmit = false;
  }catch (error) {
    this.isSubmit = false;
    this.notifyError({text: error});
  }
}

async function EditSerialization(item) {
  this.form.serialization = {
    Id: item.IdX,
    PackagingIdX: item.PackagingIdX,
    PackagingId: item.PackagingId ?? '',
    Serial: item.Serial ?? '',
    CargoId: item.CargoId ?? '',
    IsoCode: item.IsoCode ?? '',
    DescriptionOfGoods: item.DescriptionOfGoods ?? '',
    Length: item.Length ?? 0,
    Width: item.Width ?? 0,
    Height: item.Height ?? 0,
    Weight: item.Weight ?? 0, 
    Volumen: item.Volumen ?? 0, 
    AccesoryJson: item.AccesoryJson ?? [],
  };
  this.imdgItems = item?.ImdgJson ?? [];
  await this.onChagePackagingId(this.form.serialization.PackagingIdX);
  this.form.serialization.AccesoryJson = item.AccesoryJson,
  this.vForm.serialization.$touch();
}

function EditContainer(item) {
  this.SerialItem = { ...item, isEdit: true, isList: true };
  this.showBookingContainer = true;
}

function DeletePackaging(item) {
  this.$swal
    .fire(this.alertProperties({
        text: `${this.$t('label.deleteQuestion')}?`,
    }))
    .then((result) => {
      if (result.isConfirmed) {
        if (item.VisitBillOfLadingPackagingId) {
          this.form.SerializationList = this.form.SerializationList.map(Packaging => {
            if (Packaging.VisitBillOfLadingPackagingId == item.VisitBillOfLadingPackagingId) {
              return {
                ...Packaging,
                Status: 0,
              };
            } else {
              return Packaging;
            }
          });
        }else{
          this.form.SerializationList = this.form.SerializationList.filter(e => e.IdX != item.IdX);
        }
        if (this.form.serialization.Id != '' && this.form.serialization.Id == item.IdX) {
          this.Clear(false);
        }
        this.vForm.SerializationList.$touch();
      }
    });
}

async function onChagePackagingId(IdX) {
  let PackagingData = this.PackagingOptions.find(item => item.value == IdX) ?? {};
  this.form.serialization.PackagingId = PackagingData?.PackagingId ?? '';
  this.form.accesory = {
    Id: '',
    PackagingAccesoryId: '',
    Quantity: '',
    Observation: '',
  }
  this.AccesoryList = [];
  this.form.serialization.AccesoryJson = [];
  if (this.form.serialization.PackagingId) {
    await this.getAccesoryJson()
    let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    this.Packaging = {
      Weight: PackagingData?.Weight ?? 0,
      Volumen: PackagingData?.Volumen ?? 0,
      Quantity: PackagingData?.Quantity ?? 0,
      QuantityReceived: PackagingData?.QuantityReceived ?? 0,
      SerializedQuantity: this.form.SerializationList?.filter(item => item.PackagingIdX == IdX)?.length,
      CommodityName: PackagingData?.[`CommodityName${_lang}`] ?? '',
    };
  }else{
    this.Packaging = {
      Weight: 0,
      Volumen: 0,
      Quantity: 0,
      QuantityReceived: 0,
      SerializedQuantity: 0,
      CommodityName: '',
    };
  }
}

function submitImdg(item) {
  if (this.form.imdg.Id == 0) 
    this.imdgItems.push(item);
  else
    this.imdgItems = this.imdgItems.map((e) =>{
      if (e.IdX == this.form.imdg.Id) {
        return {
          ...e,
          ...item
        }
      }else {
        return e
      }
    });
}

function deleteImdg(item) {
  this.$swal
    .fire(this.alertProperties({
        text: `${this.$t('label.deleteQuestion')}?`,
    }))
    .then((result) => {
    if (result.isConfirmed) {
      if (item.BlCargoMasterImdgId) {
        this.imdgItems = this.imdgItems.map(e => {
          if (e.BlCargoMasterImdgId == item.BlCargoMasterImdgId) {
            return {
              ...e,
              Status: 0,
            };
          } else {
            return e;
          }
        });
      }else{
        this.imdgItems = this.imdgItems.filter(e => e.IdX != item.IdX);
      }
      if (this.form.imdg.Id && item.IdX == this.form.imdg.Id) {
        this.CleanInformation = true;
        setTimeout(() => {
          this.CleanInformation = false;
        }, 10);
      }
    }
  });
}

function submitAccesory(item) {
  if (this.form.accesory.Id == '') {
    this.form.serialization.AccesoryJson.push(item)
  } else {
    this.form.serialization.AccesoryJson = this.form.serialization.AccesoryJson.map(e => {
      if (e.IdX == this.form.accesory.Id) {
        return {
          ...e,
          ...item
        }
      }else {
        return e
      }
    })
  }
}

function deleteAccesory(item) {
  this.$swal
    .fire(this.alertProperties({
        text: `${this.$t('label.deleteQuestion')}?`,
    }))
    .then((result) => {
    if (result.isConfirmed) {
      if (item.BlCargoMasterDetailSerialAccesoryId) {
        this.form.serialization.AccesoryJson = this.form.serialization.AccesoryJson.map(e => {
          if (e.BlCargoMasterDetailSerialAccesoryId == item.BlCargoMasterDetailSerialAccesoryId) {
            return {
              ...e,
              FgChecked: false,
              Status: 0,
            };
          } else {
            return e;
          }
        });
      }else{
        this.form.serialization.AccesoryJson = this.form.serialization.AccesoryJson.filter(e => e.IdX != item.IdX);
      }
      if (this.form.accesory.Id && item.IdX == this.form.accesory.Id) {
        this.CleanInformation = true;
        setTimeout(() => {
          this.CleanInformation = false;
        }, 10);
      }
    }
  });
}

function changeContainer(container) {
  this.form.serialization.CargoId = container?.CargoId ?? '';
  this.form.serialization.IsoCode = container?.IsoCode ?? '';
  this.showBookingContainer = false;
  this.Vform.serialization.CargoId.$touch();
}

function updateContainer(container) {
  this.form.SerializationList = this.form.SerializationList.map(e => {
    if (e.IdX == this.SerialItem.Id) {
      return {
        ...e,
        CargoId: container?.CargoId ?? '',
        Serial: container?.ContainerCode ?? '',
        IsoCode: container?.IsoCode ?? '',
      }
    }else{
      return e;
    }
  })
  this.showBookingContainer = false;
}

function ValidatePackagingContainer(PackagingId) {
  return PackagingId.toUpperCase() == process.env.VUE_APP_PACKAGING_ID_CONTAINER;
}

function formatNumber(number, decimal) {
  return NumberFormater.formatNumber(number, decimal);
}

function Clear(All) {
  this.FgAutomatic = false;
  this.CleanInformation = true;
  this.form.serialization = {
    Id: '',
    PackagingIdX: '',
    PackagingId: '',
    Serial: '',
    CargoId: '',
    IsoCode: '',
    DescriptionOfGoods: '',
    Length: 0,
    Width: 0,
    Height: 0,
    Weight: 0, 
    Volumen: 0,
    AccesoryJson: [],
  };
  this.Packaging = {
    Weight: 0,
    Volumen: 0,
    Quantity: 0,
    QuantityReceived: 0,
    SerializedQuantity: 0,
    CommodityName: '',
  };
  this.TabIndex = 0;
  this.SerialItem = {};
  this.imdgItems = [];
  this.AccesoryList = [];
  if (All) {
    this.form.SerializationList = [];
  }
  setTimeout(() => {
    this.vForm.serialization.$reset();
    this.CleanInformation = false;
  }, 10);
}

//computed
function PackagingOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.form.PackagingList.filter(item => item.PackagingId && item.FgRequiredSerialization && item.Status == 1).forEach((e) => {
    chart.push({
      ...e,
      value: e.IdX,
      label: this.validateBooking ? `${e['PackagingName'+_lang]} ${e.TpCargoDetailCode ? `(${e.TpCargoDetailCode})`.trim() : ''}` : e['PackagingName'+_lang],
    })
  })
  return chart;
}

function Meters() {
  if (this.FgAutomatic) {
    this.vForm.serialization.Volumen.$model = 0;
  }
  return {
    Volumen: NumberFormater.setNum(this.form.serialization.Length) * NumberFormater.setNum(this.form.serialization.Width) * NumberFormater.setNum(this.form.serialization.Height),
  }
}

function computedSerializationList() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let List = this.form.SerializationList.filter(item => item.Status != 0);

  return List.map((item, index) => {
    let imdgData = item?.ImdgJson?.reduce((accumulator, currentValue, index) => {
      return {
        ImdgCod: `${index > 0 ? `${accumulator.ImdgCod},` : ''} ${currentValue.ImdgCod}`.trim(),
        PackagingGroupCod: `${index > 0 ? `${accumulator.PackagingGroupCod},` : ''} ${currentValue.PackagingGroupCod}`.trim(),
        UnNumber: `${index > 0 ? `${accumulator.UnNumber},` : ''} ${currentValue.UnNumber}`.trim(),
      }
    }, 0);
    return {
      ...item,
      Nro: index+1,
      PackagingName: item['PackagingName'+_lang] ?? this.$t('label.BreakBulk'),
      ImdgCod: imdgData?.ImdgCode ?? 'N/A',
      PackagingGroupCod: imdgData?.PackagingGroupCode ?? 'N/A',
      UnNumber: imdgData?.UnNumber ?? 'N/A',
      YardCargoBlMasterDetailSerialStatusDs: item?.[`YardCargoBlMasterDetailSerialStatusDs${_lang}`] ?? '',
    }
  });
}

function serializationfields(){
  let header = [
    { key: 'Nro', label: '#', _style: 'min-width: 45px', _classes: 'text-center', filter: false },
    { key: 'PackagingName', label: this.$t('label.packaging'),  _classes: 'text-uppercase text-center', _style: 'min-width: 200px'},
    { key: 'Serial', label: this.$t('label.serial'),  _classes: 'text-uppercase text-center', _style: 'min-width: 180px'},
    { key: 'Quantity', label: this.$t('label.plannedQuantity'), _classes: 'text-uppercase text-center', _style: 'min-width: 160px'},
    { key: 'QuantityReceived', label: this.$t('label.ReceivedQuantity'), _classes: 'text-uppercase text-center', _style: 'min-width: 160px'},
    { key: 'QuantityDispatched', label: this.$t('label.dispachedQuantity'), _classes: 'text-uppercase text-center', _style: 'min-width: 160px'},
    { key: 'Weight', label: `${this.$t('label.plannedWeight')} (KGM)`, _style:'min-width: 170px;', _classes: 'text-uppercase text-center'},
    { key: 'WeigthReception', label: `${this.$t('label.receivedWeight')} (KGM)`, _style:'min-width: 190px;', _classes: 'text-uppercase text-center'},
    { key: 'WeigthDispatch', label: `${this.$t('label.dispachedWeight')} (KGM)`, _style:'min-width: 170px;', _classes: 'text-uppercase text-center'},
    { key: 'Volumen', label: `${this.$t('label.plannedVolumen')} (M³)`, _style:'min-width: 180px;', _classes:"center-cell text-center"},
    { key: 'VolumenReception', label: `${this.$t('label.receivedVolumen')} (M³)`, _style:'min-width: 180px;', _classes:"center-cell text-center"},
    { key: 'VolumenDispatch', label: `${this.$t('label.dispachedVolumen')} (M³)`, _style:'min-width: 180px;', _classes:"center-cell text-center"},
    { key: 'ImdgCod', label: this.$t('label.imdgCode'), _classes: 'text-uppercase text-center', _style: 'min-width: 160px;' },
    { key: 'PackagingGroupCod', label: this.$t('label.PackagingGroup'), _style:'min-width: 160px;', _classes: 'text-uppercase text-center' },
    { key: 'UnNumber', label: this.$t('label.UnNumber'), _style:'min-width: 180px;', _classes:"text-center" },
    { key: 'YardCargoBlMasterDetailSerialStatusDs', label: this.$t('label.status'), _classes: 'text-uppercase text-center text-bold', _style: 'min-width: 180px'},
  ]
  if (!this.fgInfo) {
    header.unshift({ key: 'options', label: '', _style: `width: 1%; min-width: ${this.validateBooking ? 135 : 90}px`, sorter: false, filter: false });
  }
  return header;
}

export default {
  name: 'serialization-tab',
  components: {
    ImdgTab,
    AccesoryTab,
    BookingContainer,
    Money
  },
  data,
  props: {
    form: {
      type: Object,
      required: true,
      default: () => {},
    },
    vForm: Object,
    showModal: Boolean,
    isEdit: Boolean,
    Tab: Boolean,
    fgInfo: Boolean,
    validateBooking: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  methods: {
    getYardCargoById,
    getAccesoryJson,
    ContainerSearch,
    SubmitSerialization,
    EditSerialization,
    EditContainer,
    DeletePackaging,
    onChagePackagingId,
    submitImdg,
    deleteImdg,
    submitAccesory,
    deleteAccesory,
    changeContainer,
    updateContainer,
    ValidatePackagingContainer,
    formatNumber,
    Clear,
  },
  computed:{
    PackagingOptions,
    Meters,
    computedSerializationList,
    serializationfields,
    ...mapState({
			branch: state => state.auth.branch,
		})
  },
  watch: {
    showModal: function (Newval) {
      if (!Newval) {
        this.TabIndex = 0;
        this.showBookingContainer = false;
        this.Clear(true);
      }
    },
    Tab: function (Newval) {
      this.showBookingContainer = false;
    }
  }
};
</script>