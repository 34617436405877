import {
  phoneTest,
  especiales,
  onlyAlphanumeric6,
} from '@/_validations/validacionEspeciales'

import {
  required,
  email,
  helpers,
  minLength,
  maxLength,
  minValue,
  maxValue,
  not,
  sameAs,
} from "vuelidate/lib/validators";

import { NumberFormater } from "@/_helpers/funciones";

const mobileNotReq = (value) => !helpers.req(value) || phoneTest(value);
const emailToLower = (value) => email(value.toLowerCase());

export default (customBroke, validCargo, FgClassification, FgPackaging, FgSerialOrigin, WeightOrigin, fgHouse, form) => {
  let ConsolidatorId = {};
  let ConsolidatorRif = {};
  let ConsolidatorName = {};
  let ConsolidatorAddress = {};
  let ConsolidatorPrimaryPhone = {};
  let ConsolidatorSecondaryPhone = {};
  let ConsolidatorPrimaryEmail = {};
  let ConsolidatorSecondaryEmail = {};
  let validCargoMaster = {};
  let PackagingOrigin = {};
  let ValidateWeightOrigin = {};
  let validateBooking = `${form?.dataBasic?.TpBlId}`.toUpperCase() == process.env.VUE_APP_TP_BL_ID_BOOKING;
  let ValidatePackagingContainer = FgPackaging && (`${form?.Packaging?.PackagingId}`.toUpperCase() == process.env.VUE_APP_PACKAGING_ID_CONTAINER);
  let ValidateSerialContainer = (`${form?.serialization?.PackagingId}`.toUpperCase() == process.env.VUE_APP_PACKAGING_ID_CONTAINER);

  if(validCargo){
    validCargoMaster = { required };
    PackagingOrigin = { required };
    ValidateWeightOrigin = { 
      NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
      required(Value) { return NumberFormater.setNum(Value) > 0 || NumberFormater.setNum(Value) < 0 ? true : false },
    };
  }

  if (!customBroke?.consolidatorCheck) {
    ConsolidatorId = fgHouse ? { required } : {};
    ConsolidatorRif = {minLength: minLength(9), maxLength: maxLength(50)};
    ConsolidatorName = {especiales, maxLength: maxLength(50)};
    ConsolidatorAddress = {especiales, maxLength: maxLength(100)};
    ConsolidatorPrimaryPhone = {mobileNotReq, minLength: minLength(11), maxLength: maxLength(15)};
    ConsolidatorSecondaryPhone = { mobileNotReq, minLength: minLength(11),  maxLength: maxLength(15)};
    ConsolidatorPrimaryEmail = {emailToLower, maxLength: maxLength(100)},
    ConsolidatorSecondaryEmail = {emailToLower, maxLength: maxLength(100)}
  }

  return {
    form: {
      dataBasic: {
        bl: {
          required,
          onlyAlphanumericGuion: onlyAlphanumeric6,
          minLength:minLength(1),
          maxLength:maxLength(30),
        },
        BlCargoId: {},
        TpBlId: {
          required
        },
        PortActivityId: { required },
        VesselId: { required },
        VisitIdOrItineraryId: { required },
        Pol: { 
          required: required,
          NotSameAsPLAndPOD: not(sameAs('Pod')), 
        },
        Pod: { 
          required: required,
          NotSameAsPLAndPOD: not(sameAs('Pol')),
        },
        TpCargoId: { required },
        TpCargoClassifId: FgClassification ? { required } : {},

        BookingNro:{
          minValue:(1),
          maxValue:(16),
        },
        BlCargoMasterId: validCargoMaster,
        MasterBlNro: {},
        Procedence:{
          minLength:minLength(1),
          maxLength:maxLength(30),
        },
        Weight:{
          NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
          required(Value) { return NumberFormater.setNum(Value) > 0 || NumberFormater.setNum(Value) < 0 ? true : false },
        },
        WeightReceived: {},
        WeightDispatch: {},
        WeigthCertified: {},
        Volumen:{
          NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
          required(Value) { return fgHouse ? (NumberFormater.setNum(Value) > 0 || NumberFormater.setNum(Value) < 0 ? true : false) : true },
        },
        VolumenReceived: {},
        VolumenDispatch: {},
        VolumenCertified: {},
        Viscosity: { NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true } },
        Temperature: { NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true } },
        Quantity:{
          minValue: minValue (1),
        },
        QuantityReceived: {},
        QuantityDispatched: {},
        QuantityCertified: {},
        headingJson: {
          required(Value) { return !validateBooking ? (Value.length > 0 ? true : false) : true },
        },
        customsRegime: { required },
        ReceptionBpAct:{
          onlyAlphanumericGuion: onlyAlphanumeric6,
          minLength: minLength(1),
          maxLength: maxLength(20)
        },
        Dua:{
          onlyAlphanumericGuion: onlyAlphanumeric6,
          minLength: minLength(1),
          maxLength: maxLength(20)
        },
      },
      Packaging: {
        PackagingOrigin: PackagingOrigin,
        SerialOrigin: FgSerialOrigin ? { required } : {},
        WeightOrigin: ValidateWeightOrigin,
        PackagingId: FgPackaging ? { required } : {},
        TpCargoDetailId: validateBooking && ValidatePackagingContainer ? { required } : {},
        HeadingId: {},
        HeadingName: {},
        CommodityId: {},
        CommodityName: {},
        Serial: {},
        DescriptionOfGoods: {},
        Length: {
          NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
        },
        Width: {
          NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
        },
        Height: {
          NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
        },
        Weight: {
          NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
          required(Value) { return NumberFormater.setNum(Value) > 0 || NumberFormater.setNum(Value) < 0 ? true : false },
          minNumberFormater: minValue(form?.Packaging?.Id != '' ? (form?.PackagingList?.find(e => e.Status == 1 && e.IdX == form?.Packaging?.Id)?.WeigthReception) ?? 0 : 0),
          maxNumberFormater: validCargo ? maxValue(WeightOrigin) : true,
        }, 
        Quantity: { 
          minNumberFormater: minValue(form?.Packaging?.Id != '' ? (form?.PackagingList?.find(e => e.Status == 1 && e.IdX == form?.Packaging?.Id)?.QuantityReceived) ?? 1 : 1),
        },
        Volumen: {
          NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
          required(Value) { return validCargo ? (NumberFormater.setNum(Value) > 0 || NumberFormater.setNum(Value) < 0 ? true : false) : true },
          minNumberFormater: minValue(form?.Packaging?.Id != '' ? (form?.PackagingList?.find(e => e.Status == 1 && e.IdX == form?.Packaging?.Id)?.VolumenReception) ?? 0 : 0),
        },
        FgPackaging: {},
        FgRequiredSerialization: {},
      },
      PackagingList: { required(Value) { return Value.length > 0 } },
      serialization: {
        Serial: { required },
        CargoId: validateBooking && ValidateSerialContainer ? { required } : {},
        PackagingId: { required },
        Weight: {
          NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
          required(Value) { return NumberFormater.setNum(Value) > 0 || NumberFormater.setNum(Value) < 0 ? true : false },
          minNumberFormater: minValue(form?.serialization?.Id != '' ? (form?.SerializationList?.find(e => e.Status == 1 && e.IdX == form?.serialization?.Id)?.WeigthReception) ?? 0 : 0),
        }, 
        Volumen: {
          NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
          required(Value) { return validCargo ? (NumberFormater.setNum(Value) > 0 || NumberFormater.setNum(Value) < 0 ? true : false) : true },
          minNumberFormater: minValue(form?.serialization?.Id != '' ? (form?.SerializationList?.find(e => e.Status == 1 && e.IdX == form?.serialization?.Id)?.VolumenReception) ?? 0 : 0),
        },
        Length: {
          NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
        },
        Width: {
          NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
        },
        Height: {
          NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
        },
        DescriptionOfGoods: {},
        AccesoryJson: {},
      },
      SerializationList: {},
      accesory: {
        PackagingAccesoryId: { required },
        Quantity: { 
          NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
          required(Value) { return NumberFormater.setNum(Value) > 0 || NumberFormater.setNum(Value) < 0 ? true : false },
        },
        Observation: {},
      },
      imdg:{
        ImdgClassId:{
          required
        },
        ImdgId:{
          required
        },
        PackagingGroupId:{

        },
        UnNumberId:{

        },
        DescriptionOfGoodsDGS:{
            
        }
      },
      seller:{
        SellerName:{
          especiales, 
          maxLength: maxLength(50)
        },
        SellerAddress:{
          especiales, 
          maxLength: maxLength(100)
        },
        SellerPrimaryPhone:{ 
          mobileNotReq,
          minLength: minLength(11), 
          maxLength: maxLength(15) 
        },
        SellerSecondaryPhone:{  
          mobileNotReq,
          minLength: minLength(11), 
          maxLength: maxLength(15) 
        },
        SellerPrimaryEmail:{ 
          emailToLower, 
          maxLength: maxLength(100) 
        },
        SellerSecondaryEmail:{
          emailToLower, 
          maxLength: maxLength(100)
        },
      },
      customBroke:{
        CustomBrokerId: fgHouse ? { required } : {},
        CustomBrokerRif:{
          minLength: minLength(9), 
          maxLength: maxLength(50)
        },
        CustomBrokerName:{
          especiales, 
          maxLength: maxLength(100)
        },
        CustomBrokerAddress:{
          especiales, 
          maxLength: maxLength(100)
        },
        CustomBrokerPrimaryPhone:{  
          mobileNotReq,
          minLength: minLength(11), 
          maxLength: maxLength(15) 
        },
        CustomBrokerSecondaryPhone:{  
          mobileNotReq,
          minLength: minLength(11), 
          maxLength: maxLength(15) 
        },
        CustomBrokerPrimaryEmail:{  
          emailToLower, 
          maxLength: maxLength(100) 
        },
        CustomBrokerSecondaryEmail:{
          emailToLower, 
          maxLength: maxLength(100)
        },
        consolidatorCheck: {
            
        },
      },
      consolidator:{
        ConsolidatorId: ConsolidatorId,
        ConsolidatorRif: ConsolidatorRif,
        ConsolidatorName: ConsolidatorName,
        ConsolidatorAddress: ConsolidatorAddress,
        ConsolidatorPrimaryPhone: ConsolidatorPrimaryPhone,
        ConsolidatorSecondaryPhone: ConsolidatorSecondaryPhone,
        ConsolidatorPrimaryEmail: ConsolidatorPrimaryEmail,
        ConsolidatorSecondaryEmail:ConsolidatorSecondaryEmail 
      },
      consignee:{
        ConsigneeId: {
          required
        },
        ConsigneeRif:{
          minLength: minLength(9), 
          maxLength: maxLength(50)
        },
        ConsigneeName:{
          especiales, 
          maxLength: maxLength(100)
        },
        ConsigneeAddress:{
          especiales, 
          maxLength: maxLength(100)
        },
        ConsigneePrimaryPhone:{ 
          mobileNotReq,
          minLength: minLength(11), 
          maxLength: maxLength(15) 
        },
        ConsigneeSecondaryPhone:{  
          mobileNotReq,
          minLength: minLength(11), 
          maxLength: maxLength(15) 
        },
        ConsigneePrimaryEmail:{ 
          emailToLower, 
          maxLength: maxLength(100) 
        },
        ConsigneeSecondaryEmail:{
          emailToLower, 
          maxLength: maxLength(100)
        },
      },
      notify:{
        NotifyToName:{
          especiales, 
          maxLength: maxLength(100)
        },
        NotifyToAddress:{
          especiales, 
          maxLength: maxLength(100)
        },
        NotifyToPrimaryPhone:{ 
          mobileNotReq,
          minLength: minLength(11), 
          maxLength: maxLength(15) 
        },
        NotifyToSecondaryPhone:{  
          mobileNotReq,
          minLength: minLength(11), 
          maxLength: maxLength(15) 
        },
        NotifyToPrimaryEmail:{  
          emailToLower, 
          maxLength: maxLength(100) 
        },
        NotifyToSecondaryEmail:{
          emailToLower, 
          maxLength: maxLength(100)
        },
      },
      DescriptionOfGoods:{
        DescriptionOfGoods: {required}
      },
      status:{
        Status:{
          required
        },
        TransactionDate:{
          required
        },
        Motive:{

        },
      },
    },
    document:{
      BlCargoDocumentId:{ 
      },
      PortActivictyDocumentCargoId:{
        required
      },
      BlCargoDocumentDs:{
        required,
        minLength: minLength(2), 
        maxLength: maxLength(50)
      },
    },
  }
}