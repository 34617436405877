<template>
  <div>
    <CCol sm="12" class="text-end mb-3 px-0">
      <CButton
        v-if="fgNewHouse"
        color="add"
        class="d-flex align-items-center ml-auto"
        @click="toggleHouseAdd()"
      >
        <CIcon name="cil-playlist-add"/><span class="ml-1">{{`${$t('label.nuevo')} ${$t('label.house')}`}}</span>
      </CButton>
    </CCol>
      <div>
        <CRow>
            <CCol sm="12" xl="12">
                <dataTableExtended
                    class="align-center-row-datatable"
                    hover
                    sorter
                    small
                    details
                    column-filter
                    :items="formatedItems"
                    :fields="fields"
                    :noItemsView="tableText.noItemsViewText"
                    :items-per-page="tableText.itemsPerPage"
                    pagination
                >
                    <template #Status="{ item }">
                      <td class="cell-center text-center">
                        <CBadge :color="getBadge(item.Status)">
                          {{ $t('label.'+item.Status) }}
                        </CBadge>
                      </td>
                    </template>
                    <template #BlStatus="{ item }">
                      <td class="cell-center text-center">
                        <CBadge>
                          {{ item.BlStatus }}
                        </CBadge>
                      </td>
                    </template>
                    <template #YardCargoBlStatus="{ item }">
                      <td class="cell-center text-center">
                        <CBadge>
                          {{ item.YardCargoBlStatus }}
                        </CBadge>
                      </td>
                    </template>
                    <template #Detalle="{item}">
                      <td class="text-center">
                        <CButton
                          color="edit"
                          square
                          size="sm"
                          class="mr-1"
                          v-c-tooltip="{
                            content: $t('label.edit')+' '+$t('label.bl'),
                            placement: 'top-start'
                            }"
                          @click="toggleContainerEdit(item)"
                      >
                        <CIcon name="pencil" />
                      </CButton>
                      <CButton
                        square
                        size="sm"
                        color="watch"
                        class="mr-1"
                        v-c-tooltip="{
                            content: $t('label.see')+' '+$t('label.document'),
                            placement: 'top-start'
                        }"
                        @click="viewDocument(item)"
                      >
                        <CIcon name="eye" />
                      </CButton>
                      <CButton
                        square
                        size="sm"
                        color="watch"
                        @click="getBl(item)"
                        v-c-tooltip="{
                          content: `${$t('label.see')} ${$t('label.bl')}`,
                          placement: 'top-start'
                        }"
                      >
                        <CIcon name='cil-list' />
                      </CButton>
                    </td>
                  </template>
              </dataTableExtended>
            </CCol>
        </CRow>
      </div>
    <ModalBlContainer
      :modal.sync="ModalBlContainer"
      :ContainerItem="ContainerItem"
      :items ="items"
      :title="titleModal"
      :fgInfo="fgInfo"
      :fgHouse="fgHouse"
      :isEdit="isEdit"
      @close="closeModal"
      @Update-list="Update"
    />
    <ModalBl
      :modal.sync="ModalBl"
      :Bl="Bl"
      :BlMaster="BlMaster"
      :Container="dataContainer"
      :items="BlMaster?.HouseJson ?? []"
      :title="titleModal"
      :isEdit="isEdit"
      :fgHouse="true"
      :fgContainer="true"
      @close="closeModal"
      @Update-list="Update"
    />
    <ModalBlDocument
      :modal.sync="ModalBlDocument"
      :title="titleDocumentsModal"
      :documentList="documentList"
      @close="closeModal"
    />
  </div>
</template>
<script>

import GeneralMixin from '@/_mixins/general';
import BlValidations from '@/_validations/bl-query/blValidations';
import { mapState } from 'vuex';
import ModalBlContainer from './modal-bl-container';
import ModalBlDocument from './modal-bl-document';
import ModalBl from '@/pages/bl-query/taps/basicData/modal-bl/index';
import { DateFormater, removeTags, trimRemoveSpecialCharacters} from '@/_helpers/funciones';
import { formatMilDecimal } from '@/_validations/validacionEspeciales';

//DATA-------------------------
function data() {
  return {
    ModalBlContainer: false,
    ModalBlDocument: false,
    ModalBl: false,
    fgInfo: false,
    fgHouse: false,
    ContainerItem:{},
    BlMaster: {},
    Bl: {},
    titleModal: '',
    titleDocumentsModal: '',
    isEdit: false,
    items: [],
    documentList: [],
  }
}

//methods
async function getBLList() {
  if(this.CargoId && this.tabIndex === 0 && this.yardCollapse == 2 && !this.ValidateTpCargoStatus){
    this.$store.state.yardManagement.loading = true;
    await this.$http.get('YardBlCargolist-by-CargoId',{ CargoId: this.CargoId})
    .then(response => {
      let listado = response.data.data;
      if (listado && listado.length != 0){
          this.items = listado;
      }
    })        
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
    });
  }
  this.$store.state.yardManagement.isSelect = false;
}

async function getYardCargo() {
  await this.$http.get('YardCargo-by-Id', { YardCargoId: this.YardCargoId, YardId: this.YardId })
    .then(response => {
      let List = response.data.data;
      if (List&&List.length!=0) {
        if(this.$store.state.yardManagement.dataContainer.YarCargoDispachtId){
          let yardCargoReception = '', yardCargoDispatch = ''; 

          yardCargoReception = this.$store.state.yardManagement.dataContainer.YardCargoId;
          yardCargoDispatch = this.$store.state.yardManagement.dataContainer.YarCargoDispachtId;

          this.$store.state.yardManagement.dataContainer = {...List[0], YardCargoId : yardCargoReception, YarCargoDispachtId: yardCargoDispatch};
        } 
        else{
          this.$store.state.yardManagement.dataContainer = List[0];
        }
        
        if (process.env.VUE_APP_YARD_CARGO_STATUS_RECEPTION == List[0].YardCargoStatus.toUpperCase()) {
          this.$store.state.yardManagement.globalSelectContainer = 
            this.$store.state.yardManagement.globalSelectContainer.map(Select=>{
              return Select.CargoId == this.CargoId ? List[0] : Select;
            });
        }
      }else{
        this.$store.state.yardManagement.dataContainer = {};
        this.$store.state.yardManagement.ListReceivedContainers = [];
        this.$store.state.yardManagement.ListDispatchedContainers = [];
        this.$store.state.yardManagement.ListRecognitionContainers = [];
      }
    })
    .catch( err => {
      this.$store.state.yardManagement.dataContainer = {};
      this.$store.state.yardManagement.ListReceivedContainers = [];
      this.$store.state.yardManagement.ListDispatchedContainers = [];
      this.$store.state.yardManagement.ListRecognitionContainers = [];
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function getBlMaster() {
  await this.$http.get("BlCargo-by-Id", { BlCargoId: this.MasterBlCargoId})
    .then(response => {
      let BlMaster = response?.data?.data?.[0] ?? undefined;
      if (BlMaster) {
        this.BlMaster = BlMaster;
      }
    }).catch( err => {
        this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

function getBl(item) {
  this.$store.state.yardManagement.loading = true;
  this.$http.get("BlCargo-by-Id", { BlCargoId: item.BlCargoId})
  .then(response => {
    let Bl = response?.data?.data?.[0] ?? undefined;
    this.$store.state.yardManagement.BlTabIndex = 0;
    if (Bl) {
      this.$store.state.yardManagement.dataBl = Bl;
      this.$store.state.yardManagement.FgBlNavigation = true;
      this.$store.state.yardManagement.BlCollapse = 1;
      this.$store.state.yardManagement.yardCollapse = 4;
    }
  }).catch( err => {
      this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.$store.state.yardManagement.loading = false;
  });
}

async function getBlHouse(item) {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get("BlCargo-by-Id", { BlCargoId: item.BlCargoId})
  .then(response => {
    this.Bl = response?.data?.data?.[0] ?? {};
  }).catch( err => {
      this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.$store.state.yardManagement.loading = false;
  });
}

function toggleHouseAdd() {
  try{
    if (!this.MasterBlCargoId) {
      throw this.$t("validation.MissingInformationBl");
    }

    this.$v.form.dataBasic.$touch();
    this.$v.form.PackagingList.$touch();
    this.$v.form.seller.$touch();
    this.$v.form.customBroke.$touch();
    this.$v.form.consolidator.$touch();
    this.$v.form.consignee.$touch();
    this.$v.form.notify.$touch();
    this.$v.form.DescriptionOfGoods.$touch();

    if (this.$v.form.dataBasic.$error || this.$v.form.seller.$error || this.$v.form.customBroke.$error || this.$v.form.consolidator.$error || this.$v.form.consignee.$error || 
      this.$v.form.notify.$error || this.$v.form.DescriptionOfGoods.$error || this.$v.form.PackagingList.$error) {
      throw this.$t("validation.MissingInformationBl");
    }
    this.titleModal = `${this.$t('label.nuevo')} ${this.$t('label.BlHouse')}`;
    this.ModalBl = true;
  }catch (error) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: error,
      type: "error"
    });
  }
}

async function toggleContainerEdit(item) {
  this.ContainerItem = item;
  this.fgInfo = !item?.FgEdit;
  this.fgHouse = item.TpBlId == process.env.VUE_APP_TP_BL_ID_HOUSE;
  this.isEdit = true;
  if (this.fgHouse) {
    await this.getBlHouse(item);
    this.titleModal = `${this.$t('label.edit')} ${this.$t('label.bl')}: ${this.Bl.BlNro}`;
    this.ModalBl = true;
  }else {
    this.titleModal = `${this.$t('label.edit')} ${this.$t('label.bl')}: ${this.ContainerItem.BlNro}`;
    this.ModalBlContainer = true;
  }
}

function viewDocument(item){
  this.ModalBlDocument = true;
  let title = item.BlNro;
  this.documentList = item.DocumentJson;
  this.titleDocumentsModal = this.$t('label.blDocuments')+': '+title;
}

async function Update() {
  this.$store.state.yardManagement.loading = true;
  this.isEdit = false;
  await this.getYardCargo();
  await this.getBLList();
}

function closeModal(){
  this.ModalBlContainer = false;
  this.ModalBlDocument = false;
  this.ModalBl = false;
  this.documentList = [];
  this.ContainerItem = {};
  this.Bl = {};
  this.titleModal = '';
  this.titleDocumentsModal = '';
  this.isEdit = false;
}

//computed
function formatedItems() {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  if (this.items.length > 0) {
    return this.items.map((item) => {
      return {
        ...item,
        Nro: item.IdX,
        BookingNro: item.BookingNro ? item.BookingNro : 'N/A',
        CustomOperation:  item[`CustomOperationDs${_lang}`] ? item[`CustomOperationDs${_lang}`] : 'N/A',
        CustomBrokerName: item.CustomBrokerName ?? 'N/A',
        ConsigneeName: item.ConsigneeName ?? 'N/A',
        Dua: item.Dua ?  item.Dua : 'N/A',
        Procedence: item.Procedence ? item.Procedence : 'N/A',
        ReceptionBpAct: item.ReceptionBpAct ? item.ReceptionBpAct : 'N/A',
        Quantity: formatMilDecimal(item?.Quantity?.toFixed(2)) ?? '0,00',
        Weigth: formatMilDecimal(item?.Weigth?.toFixed(2)) ?? '0,00',
        Volumen: formatMilDecimal(item?.Volumen?.toFixed(2)) ?? '0,00',
        DescriptionOfGoods: trimRemoveSpecialCharacters(removeTags(item.DescriptionOfGoods)) ?? 'N/A',
        DescriptionOfGoodsShowEdit: item.DescriptionOfGoods,
        TransaLogin: item.TransaLogin ?? 'N/A',
        FormatedDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate): 'N/A',
        BlStatus: item[`BlStatus${_lang}`],
        YardCargoBlStatus: item[`YardCargoBlStatus${_lang}`],
        _classes: item.Status != 'ACTIVO' ? 'table-danger' : 'color-gradient',
        _style: item.Status == 'ACTIVO' ? `background: ${item?.YardCargoBlStatusColor}` : '',
      };
    });
  }
}

function fields(){
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return [
    { key: "Detalle", label: "",_style:'width:10%;min-width:135px;', filter: false,},
    { key: 'Nro', label: '#',_style: 'min-width: 60px;', filter: false, _classes: 'text-uppercase text-center'},
    { key: 'BlNro',label: this.$t('label.bl'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center'},
    { key: 'TpBlName', label: this.$t('label.type'),  _classes: 'text-uppercase text-center', _style: 'min-width:160px; text-align:center;' },
    { key: 'ConsigneeName', label: this.$t('label.consignee'), _style:'min-width:150px;', _classes: 'text-uppercase text-center' },
    { key: 'CustomBrokerName', label: this.$t('label.customBroker'),  _style:'min-width:170px; text-align:center;', _classes:"text-center"  },
    { key: 'CustomOperation', label: this.$t('label.customsRegime'),  _style:'min-width:220px; text-align:center;', _classes:"text-center"  },
    { key: 'BookingNro', label: this.$t('label.nBooking'),  _classes: 'text-uppercase text-center', _style: 'min-width:180px; text-align:center;' },
    { key: 'DescriptionOfGoods', label: this.$t('label.inventoryContains'), _style:'min-width:220px; text-align:center;', _classes:"text-center" },
    { key: 'Dua', label: this.$t('label.nDua'), _style:'min-width:140px; text-align:center;', _classes:"text-center" },
    { key: 'ReceptionBpAct', label: this.$t('label.BolipuertoCertificateEntry'), _style:'min-width:300px; text-align:center;', _classes:"text-center" },
    { key: 'Procedence', label: this.$t('label.Origin'),  _style:'min-width:150px; text-align:center;', _classes:"text-center"  },
    { key: 'Quantity', label: this.$t('label.quantity'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center' },
    { key: 'Weigth', label: this.$t('label.weight')+' '+('(KGM)'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
    { key: 'Volumen', label: this.$t('label.volume')+' '+('(M³)'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center' },
    { key: 'BlStatus', label: this.$t('label.statusCertificate'), _style:'min-width:200px;', _classes:"text-center text-bold" },
    { key: 'YardCargoBlStatus', label: this.$t('label.statusYardWarehouse'), _style:'min-width:260px;', _classes:"text-center text-bold" },
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'min-width: 180px;', _classes:"text-center"},
    { key: 'FormatedDate', label: this.$t('label.registerDate'), _style:'min-width: 180px;', _classes:"text-center"},
    { key: 'Status', label: this.$t('label.status'),  _style:'min-width: 180px; text-align:center;', _classes:"text-center" },
  ];
}

function Tab() {
  return this.tabIndex == 0 && this.yardCollapse == 2 && !this.isSelect;
}

function form() {
  if (this.MasterBlCargoId && this.fgNewHouse) {
    let SerializationList = [];
    let PackagingList = this.BlMaster?.DetailJson?.map((e, index) => {
      if (e?.SerialJson) {
        SerializationList.push(...e.SerialJson.map((el, index2) => {return {...el, IdX: index2+1, Weight: el.Weigth, Status: 1}}));
      }
      return {...e, IdX: index+1, Weight: e.Weigth, Status: 1}
    }) ?? [];
    return {
      dataBasic:{
        BlCargoId: this.BlMaster?.BlCargoId ?? '',
        bl: this.BlMaster?.BlNro ?? '',
        TpBlId: this.BlMaster?.TpBlId ?? '',
        BlCargoMasterId: this.BlMaster?.BlCargoMasterId ?? '',
        MasterBlNro: this.BlMaster?.TpBlId == process.env.VUE_APP_TP_BL_ID_HOUSE ? (this.BlMaster?.MasterBlNro ?? '') : 'N/A',
        VesselId: this.BlMaster?.VesselId ?? '',
        VisitIdOrItineraryId: this.BlMaster?.VisitId ?? (this.BlMaster?.ItineraryId ?? ''),
        PortActivityId: this.BlMaster?.PortActivityId ?? '',
        Pol: this.BlMaster?.LoadPortId ?? '',
        Pod: this.BlMaster?.DischargePortId ?? '',
        TpCargoId: this.BlMaster?.TpCargoId ?? '',
        TpCargoClassifId: this.BlMaster?.TpCargoClassifId ?? '',
        BookingNro: this.BlMaster?.BookingNro ?? '',
        Procedence: this.BlMaster?.Procedence ?? '',
        Weight: this.BlMaster?.Weigth ?? 0,
        Volumen: this.BlMaster?.Volumen ?? 0,
        Quantity: this.BlMaster?.Quantity ?? 0,
        Viscosity: this.BlMaster?.Viscosity ?? 0,
        Temperature: this.BlMaster?.Temperature ?? 0,
        headingJson: this.BlMaster?.HeadingJson ?? [],
        customsRegime: this.BlMaster?.CustomOperationId ?? '',
        ReceptionBpAct: this.BlMaster?.ReceptionBpAct ?? '',
        Dua: this.BlMaster?.Dua ?? '',
        Status: this.BlMaster?.FgActBlCargoMaster? 1:0,
        validCargoMaster: this.BlMaster?.TpBlId == process.env.VUE_APP_TP_BL_ID_HOUSE,
      },
      PackagingList: PackagingList,
      SerializationList: SerializationList,
      seller:{
        SellerName: this.BlMaster?.SellerName ?? '',
        SellerAddress: this.BlMaster?.SellerAddress ?? '',
        SellerPrimaryPhone: this.BlMaster?.SellerPrimaryPhone ?? '',
        SellerSecondaryPhone: this.BlMaster?.SellerSecondaryPhone ?? '',
        SellerPrimaryEmail: this.BlMaster?.SellerPrimaryEmail ?? '',
        SellerSecondaryEmail: this.BlMaster?.SellerSecondaryEmail ?? ''
      },
      customBroke:{
        CustomBrokerId: this.BlMaster?.CustomBrokerId ?? '',
        CustomBrokerRif: this.BlMaster?.CustomBrokerRif ?? '',
        CustomBrokerName: this.BlMaster?.CustomBrokerName ?? '',
        CustomBrokerAddress: this.BlMaster?.CustomBrokerAddress ?? '',
        CustomBrokerPrimaryPhone: this.BlMaster?.CustomBrokerPrimaryPhone ?? '',
        CustomBrokerSecondaryPhone: this.BlMaster?.CustomBrokerSecondaryPhone ?? '',
        CustomBrokerPrimaryEmail: this.BlMaster?.CustomBrokerPrimaryEmail ?? '',
        CustomBrokerSecondaryEmail: this.BlMaster?.CustomBrokerSecondaryEmail ?? '',
        consolidatorCheck: this.BlMaster?.ConsolidatorId ? true : false,
      },
      consolidator:{
        ConsolidatorId: this.BlMaster?.ConsolidatorId ?? '',
        ConsolidatorRif: this.BlMaster?.ConsolidatorRif ?? '',
        ConsolidatorName: this.BlMaster?.ConsolidatorName ?? '',
        ConsolidatorAddress: this.BlMaster?.ConsolidatorAddress ?? '',
        ConsolidatorPrimaryPhone: this.BlMaster?.ConsolidatorPrimaryPhone ?? '',
        ConsolidatorSecondaryPhone: this.BlMaster?.ConsolidatorSecondaryPhone ?? '',
        ConsolidatorPrimaryEmail: this.BlMaster?.ConsolidatorPrimaryEmail ?? '',
        ConsolidatorSecondaryEmail: this.BlMaster?.ConsolidatorSecondaryEmail ?? ''
      },
      consignee:{
        ConsigneeId: this.BlMaster?.ClientTpId ?? '',
        ConsigneeRif: this.BlMaster?.ClientRif ?? '',
        ConsigneeAddress: this.BlMaster?.ClientAddress ?? '',
        ConsigneeName: this.BlMaster?.ClientName ?? '',
        ConsigneePrimaryPhone: this.BlMaster?.ClientPrimaryPhone ?? '',
        ConsigneeSecondaryPhone: this.BlMaster?.ClientSecondaryPhone ?? '',
        ConsigneePrimaryEmail: this.BlMaster?.ClientPrimaryEmail ?? '',
        ConsigneeSecondaryEmail: this.BlMaster?.ClientSecondaryEmail ?? '', 
      },
      notify:{
        NotifyToName: this.BlMaster?.NotifyToName ?? '',
        NotifyToAddress: this.BlMaster?.NotifyToAddress ?? '',
        NotifyToPrimaryPhone: this.BlMaster?.NotifyToPrimaryPhone ?? '',
        NotifyToSecondaryPhone: this.BlMaster?.NotifyToSecondaryPhone ?? '',
        NotifyToPrimaryEmail: this.BlMaster?.NotifyToPrimaryEmail ?? '',
        NotifyToSecondaryEmail: this.BlMaster?.NotifyToSecondaryEmail ?? ''
      },
      DescriptionOfGoods:{
        DescriptionOfGoods: this.BlMaster?.DescriptionOfGoods ?? ''
      },
    }
  }else{
    return {}
  }
}

export default {
  name: 'bl-index',
  components: {
    ModalBlContainer,
    ModalBlDocument,
    ModalBl,
  },
  validations(){ return BlValidations(this.form?.customBroke, this.form.dataBasic?.validCargoMaster)},
  props: { 
    ValidateTpCargoStatus: Boolean, 
    MasterBlCargoId: String,
    isCoverWareHouse: Boolean,
    fgNewHouse: Boolean,
  },
  data,
  mixins: [GeneralMixin],
  methods: {
    getBLList,
    getYardCargo,
    getBlMaster,
    getBl,
    getBlHouse,
    toggleHouseAdd,
    toggleContainerEdit,
    viewDocument,
    Update,
    closeModal,
  },
  computed: {
    fields,
    formatedItems,
    Tab,
    form,
    ...mapState({
      CargoId: state => state.yardManagement.dataContainer.CargoId,
      YardCargoId: state => state.yardManagement.dataContainer.YardCargoId,
      YardId: state => state.yardManagement.yardData.YardId,
      dataContainer: state => state.yardManagement.dataContainer,
      GeneralList: state => state.yardManagement.GeneralList,
      yardCollapse: state => state.yardManagement.yardCollapse,
      isSelect: state => state.yardManagement.isSelect,
      tabIndex: state => state.yardManagement.ContainerTabIndex,
      FgPositioning: state => state.yardManagement.FgPositioning,
    })
  },
  watch: {
    Tab: async function (val){
      if(val){
        this.$store.state.yardManagement.loading = true;
        this.$store.state.yardManagement.dropContainerMovement = 0;
        this.$store.state.yardManagement.dropItemInspection = 0;
        this.items = [];
        this.BlMaster = {};
        if (this.fgNewHouse && this.MasterBlCargoId) {
          await this.getBlMaster();
        }
        this.getBLList();
      }
    },
  }
};
</script>
<style scoped>
.Containers-header{
  background: #4e5a70;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}
.Containers-Search{
  background: #ffffff;
  border-radius: 10px;
}
.form-control-Search{
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid;
  color: #768192;
  background-color: #fff;
  border-color: #fff;
  border-radius: 10px;
  outline: none;
}
.Containers-data{
  margin: 5px;
  padding: 5px 15px;
  background-color: #fff;
  border-radius: 10px;
}

.Container-items{
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}
.value-Table{
  background-color: white;
}
</style>