<template>
  <div>
    <CRow class="align-items-center mt-3" >
      <CCol v-if="!isEdit && !fgHouse" sm="12" lg="4">
        <CSelect
          size="sm"
          :placeholder="$t('label.select')"
          addLabelClasses="required text-right"
          :label="$t('label.type')"
          :horizontal="{label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
          :options="blTypeOptions"
          v-model="vForm.dataBasic.TpBlId.$model"
          :is-valid="hasError(vForm.dataBasic.TpBlId)"
          :invalid-feedback="errorMessage(vForm.dataBasic.TpBlId)"
          :disabled="fgInfo"
        />
      </CCol>
      <CCol sm="12" lg="4" v-else>
        <CInput
          horizontal
          size="sm"
          :label="$t('label.blType')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          addLabelClasses="required text-right" 
          disabled
          :is-valid="hasError(vForm.dataBasic.TpBlId)"
          :invalid-feedback="errorMessage(vForm.dataBasic.TpBlId)"
          v-model="TpBlName" 
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="4">
        <CInput
          size="sm"
          :label="!validateBooking ? $t('label.bl') : $t('label.booking')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :placeholder="''"
          v-uppercase 
          addLabelClasses="required text-right"
          v-model="vForm.dataBasic.bl.$model"
          :is-valid="hasError(vForm.dataBasic.bl)"
          :invalid-feedback="errorMessage(vForm.dataBasic.bl)" 
          :disabled="fgInfo"
        >
        </CInput>
      </CCol>
      <CCol v-if="!validateBooking" sm="12" lg="4">
        <CInput
          horizontal
          size="sm"
          :label="$t('label.blCargoMaster')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :addLabelClasses="validCargoMaster ? 'required text-right' : 'text-right'" 
          disabled
          :is-valid="hasError(vForm.dataBasic.BlCargoMasterId)"
          :invalid-feedback="errorMessage(vForm.dataBasic.BlCargoMasterId)"
          v-model="vForm.dataBasic.MasterBlNro.$model" 
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="4">
        <div class="form-group form-row" rol="group">
          <label class="col-form-label-sm col-sm-12 col-lg-5 text-right required Label mb-0">{{$t('label.vessel')}}</label>
          <div class="col-sm-12 col-lg-7">
            <v-select 
              id="v-select-small"
              style="font-size: 11px;"
              :placeholder="$t('label.select')"
              :class="!vForm.dataBasic.VesselId.$dirty ? '' : (vForm.dataBasic.VesselId.$error ? 'select-client--error' : 'select-client--correct')"
              :options="VesselOptions"
              :reduce="option => option.value"
              v-model="vForm.dataBasic.VesselId.$model"
              :disabled="isEdit || fgInfo || !form.dataBasic.TpBlId"
              @input="onInputVesselId"
            >
              <template #no-options="{ }">
                {{$t('label.noResultsFound')}}
              </template>
            </v-select>
            <div class="invalid-feedback d-block" v-if="vForm.dataBasic.VesselId.$error">
              {{ errorMessage(vForm.dataBasic.VesselId) }}
            </div>
          </div>
        </div>
      </CCol>
      <CCol sm="12" lg="4">
        <div class="form-group form-row" rol="group">
          <label class="d-flex col-form-label-sm col-sm-12 col-lg-5 required align-items-center justify-content-end mb-0">{{$t('label.visitItineraty')}}</label>
          <div class="col-sm-12 col-lg-7">
            <v-select 
              id="v-select-small"
              style="font-size: 11px;"
              :placeholder="$t('label.select')"
              class="v-select-auto"
              :class="!vForm.dataBasic.VisitIdOrItineraryId.$dirty ? '' : (vForm.dataBasic.VisitIdOrItineraryId.$error ? 'select-client--error' : 'select-client--correct')"
              :options="VisitOrItineraryOptions"
              :reduce="option => option.value"
              v-model="vForm.dataBasic.VisitIdOrItineraryId.$model"
              :disabled="!form.dataBasic.VesselId || isEdit || fgInfo"
              @input="onInputVisitIdOrItineraryId"
            >
              <template #no-options="{ }">
                {{$t('label.noResultsFound')}}
              </template>
              <template #option="{ Json, label, value }">
                <p v-if="value" class="m-0">{{ `${Json.VesselName} - ${Json.Voyage}` }}</p>
                <p v-else class="m-0">{{ label }}</p>
                <cite v-if="value">{{ `${Json.LabelArrival ?? ''} ${formatDateTimeWithSlash(Json.Arrival)} - ${Json.LabelDeparture ?? ''} ${formatDateTimeWithSlash(Json.Departure)}` }} </cite>
              </template>
            </v-select>
            <div class="invalid-feedback d-block" v-if="vForm.dataBasic.VisitIdOrItineraryId.$error">
              {{ errorMessage(vForm.dataBasic.VisitIdOrItineraryId) }}
            </div>
          </div>
        </div>
      </CCol>
      <CCol sm="12" lg="4">
        <CSelect
          size="sm"
          :placeholder="$t('label.select')"
          addLabelClasses="required text-right"
          :label="$t('label.activity')"
          :horizontal="{label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
          :options="ActivityOptions"
          v-model="vForm.dataBasic.PortActivityId.$model"
          :is-valid="hasError(vForm.dataBasic.PortActivityId)"
          :invalid-feedback="errorMessage(vForm.dataBasic.PortActivityId)"
          :disabled="!form.dataBasic.VisitIdOrItineraryId || isEdit || fgInfo"
          @change="onPortActivityAlert"
        />
      </CCol>
      <CCol sm="12" lg="4">
        <CSelect
          size="sm"
          :placeholder="$t('label.select')"
          addLabelClasses="required text-right"
          :label="'POL'"
          :horizontal="{label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
          :options="PolOptions"
          v-model="vForm.dataBasic.Pol.$model"
          :invalid-feedback="errorMessage(vForm.dataBasic.Pol)"
          :is-valid="hasError(vForm.dataBasic.Pol)"
          :disabled="!form.dataBasic.PortActivityId || validCargoMaster || fgInfo"
        />
      </CCol>
      <CCol sm="12" lg="4">
        <div class="form-group form-row">
          <label class="required text-right col-form-label-sm mb-0 col-sm-12 col-lg-5 mb-0">{{'POD'}}</label>
          <div class="col-sm-12 col-lg-7">
            <CSelect
              v-uppercase
              size="sm"
              :placeholder="$t('label.select')"
              :options="PodOptions"
              class="mb-0"
              v-model="vForm.dataBasic.Pod.$model"
              :invalid-feedback="errorMessage(vForm.dataBasic.Pod)"
              :is-valid="hasError(vForm.dataBasic.Pod)"
              :disabled="!form.dataBasic.PortActivityId || validCargoMaster || fgInfo"
            />
          </div>
        </div>
      </CCol>

      <CCol sm="12" lg="4">
        <CSelect
          size="sm"
          :placeholder="$t('label.select')"
          addLabelClasses="required text-right"
          :label="$t('label.group')"
          :horizontal="{label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
          :options="GroupOptions"
          v-model="vForm.dataBasic.TpCargoId.$model"
          :is-valid="hasError(vForm.dataBasic.TpCargoId)"
          :invalid-feedback="errorMessage(vForm.dataBasic.TpCargoId)"
          :disabled="fgInfo"
          @change="getClassificationAndCommodity"
        />
      </CCol>
      <CCol sm="12" lg="4">
        <CSelect
          size="sm"
          :placeholder="$t('label.select')"
          :addLabelClasses="form.dataBasic?.FgClassification ? 'text-right required' : 'text-right'"
          :label="$t('label.classification')"
          :horizontal="{label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
          :options="ClassificationOptions"
          v-model="vForm.dataBasic.TpCargoClassifId.$model"
          :is-valid="hasError(vForm.dataBasic.TpCargoClassifId)"
          :invalid-feedback="errorMessage(vForm.dataBasic.TpCargoClassifId)"
          :disabled="!form.dataBasic.TpCargoId || fgInfo"
        />
      </CCol> 

      <CCol sm="12" lg="4">
        <div class="form-group form-row">
          <label :class="!validateBooking ? 'required' : ''" class="text-right col-sm-12 col-lg-5 col-form-label-sm mb-0">{{`${$t('label.commodity')}`}}</label>
          <div class="input-group col-sm-12 col-lg-7 input-group-sm" :class="fgInfo ? 'display_info' : ''">
            <multiselect 
              v-model.trim="vForm.dataBasic.headingJson.$model"
              :options="CommodityList"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :placeholder="$t('label.select')"
              :label="$i18n.locale=='en' ? 'CommodityNameEn' : 'CommodityNameEs'"
              track-by="CommodityId"
              class="col-sm-12 p-0" 
              :class="!vForm.dataBasic.headingJson.$dirty ? '' : (vForm.dataBasic.headingJson.$error ? 'no-valido' : 'valido')"       
              style="min-height: 30px;"
              :disabled="fgInfo"
            >
              <template slot="selection" slot-scope="{ values, isOpen }" v-if="!fgInfo">
                <span class="multiselect__single m-0" v-if="values.length &amp;&amp; !isOpen">
                {{ values.length}} {{$t('label.commodity')}}{{('(S)')}}
                </span>
              </template>
            </multiselect> 
            <div class="invalid-feedback d-block" v-if="vForm.dataBasic.headingJson.$error">
              {{ errorMessage(vForm.dataBasic.headingJson) }}
            </div>
          </div>
        </div>
      </CCol>
      <CCol sm="12" lg="4">
        <CSelect
          horizontal
          size="sm"
          :label="$t('label.customsRegime')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          addLabelClasses="required text-right" 
          :options="customsRegimeOptions"
          v-model.trim="vForm.dataBasic.customsRegime.$model"
          :is-valid="hasError(vForm.dataBasic.customsRegime)"
          :invalid-feedback="errorMessage(vForm.dataBasic.customsRegime)"
          :disabled="fgInfo || fgHouse"
        />
      </CCol>
      
      <CCol v-if="!validateBooking" sm="12" lg="4">
        <CInput
          size="sm"
          :label="$t('label.nBooking')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :placeholder="''"
          v-uppercase 
          addLabelClasses="text-right" 
          v-model="vForm.dataBasic.BookingNro.$model"
          :is-valid="hasError(vForm.dataBasic.BookingNro)"
          :invalid-feedback="errorMessage(vForm.dataBasic.BookingNro)" 
          :disabled="validCargoMaster || fgInfo"
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="4">
        <div class="form-group form-row">
          <label class="text-right col-sm-12 col-lg-5 required col-form-label-sm mb-0">{{`${$t('label.PlanQuantity')}`}}</label>
          <div class="input-group col-sm-12 col-lg-7 input-group-sm">
            <money
              size="sm"
              v-bind="Quantitymeasure"
              :class="!vForm.dataBasic.Quantity.$dirty ? 'form-control' : (vForm.dataBasic.Quantity.$error ? 'form-control is-invalid' : 'form-control is-valid')"
              v-model.trim="vForm.dataBasic.Quantity.$model"
              value="0"
              maxlength= "12"
              disabled
            >
            </money>
            <div class="invalid-feedback" v-if="vForm.dataBasic.Quantity.$error">
              {{ errorMessage(vForm.dataBasic.Quantity) }}
            </div>
          </div>
        </div>
      </CCol>
      <CCol sm="12" lg="4">
        <div class="form-group form-row">
          <label class="text-right col-sm-12 col-lg-5 required col-form-label-sm mb-0">{{`${$t('label.PlanWeight')} (KGM)`}}</label>
          <div class="input-group col-sm-12 col-lg-7 input-group-sm">
            <money
              size="sm"
              v-bind="Quantitymeasure" 
              :class="!vForm.dataBasic.Weight.$dirty ? 'form-control' : (vForm.dataBasic.Weight.$error ? 'form-control is-invalid' : 'form-control is-valid')"
              v-model.trim="vForm.dataBasic.Weight.$model"
              value="0"
              maxlength= "12"
              disabled
            >
            </money>
            <div class="invalid-feedback" v-if="vForm.dataBasic.Weight.$error">
              {{ errorMessage(vForm.dataBasic.Weight) }}
            </div>
          </div>
        </div>
      </CCol>
      <CCol sm="12" lg="4">
        <div class="form-group form-row">
          <label class="text-right col-sm-12 col-lg-5 col-form-label-sm mb-0" :class="fgHouse ? 'required' : ''">{{`${$t('label.PlanVolume')} (M³)`}}</label>
          <div class="input-group col-sm-12 col-lg-7 input-group-sm">
            <money
              size="sm"
              v-bind="measure" 
              :class="!vForm.dataBasic.Volumen.$dirty ? 'form-control' : (vForm.dataBasic.Volumen.$error ? 'form-control is-invalid' : 'form-control is-valid')"
              v-model.trim="vForm.dataBasic.Volumen.$model"
              value="0"
              maxlength= "12"
              disabled
            >
            </money>
            <div class="invalid-feedback" v-if="vForm.dataBasic.Volumen.$error">
              {{ errorMessage(vForm.dataBasic.Volumen) }}
            </div>
          </div>
        </div>
      </CCol>
      <CCol sm="12" lg="4">
        <div class="form-group form-row">
          <label class="text-right col-sm-12 col-lg-5 col-form-label-sm mb-0">{{`${$t('label.temperature')}`}}</label>
          <div class="input-group col-sm-12 col-lg-7 input-group-sm">
            <money
              size="sm"
              v-bind="measure" 
              :class="!vForm.dataBasic.Temperature.$dirty ? 'form-control' : (vForm.dataBasic.Temperature.$error ? 'form-control is-invalid' : 'form-control is-valid')"
              v-model.trim="vForm.dataBasic.Temperature.$model"
              value="0"
              maxlength= "12"
              :disabled="fgInfo"
            >
            </money>
            <div class="invalid-feedback" v-if="vForm.dataBasic.Temperature.$error">
              {{ errorMessage(vForm.dataBasic.Temperature) }}
            </div>
          </div>
        </div>
      </CCol>
      <CCol sm="12" lg="4">
        <div class="form-group form-row">
          <label class="text-right col-sm-12 col-lg-5 col-form-label-sm mb-0">{{`${$t('label.viscosity')}`}}</label>
          <div class="input-group col-sm-12 col-lg-7 input-group-sm">
            <money
              size="sm"
              v-bind="measure" 
              :class="!vForm.dataBasic.Viscosity.$dirty ? 'form-control' : (vForm.dataBasic.Viscosity.$error ? 'form-control is-invalid' : 'form-control is-valid')"
              v-model.trim="vForm.dataBasic.Viscosity.$model"
              value="0"
              maxlength= "12"
              :disabled="fgInfo"
            >
            </money>
            <div class="invalid-feedback" v-if="vForm.dataBasic.Viscosity.$error">
              {{ errorMessage(vForm.dataBasic.Viscosity) }}
            </div>
          </div>
        </div>
      </CCol>
      <CCol sm="12" lg="4">
        <CInput
          size="sm"
          :label="$t('label.Origin')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :placeholder="''"
          v-uppercase 
          addLabelClasses="text-right"
          v-model="vForm.dataBasic.Procedence.$model"
          :is-valid="hasError(vForm.dataBasic.Procedence)"
          :invalid-feedback="errorMessage(vForm.dataBasic.Procedence)"
          :disabled="validCargoMaster || fgInfo"
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="4">
        <CInput
          size="sm"
          :label="`${$t('label.nDua')}`"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.dataBasic.Dua.$model"
          :is-valid="hasError(vForm.dataBasic.Dua)"
          :invalid-feedback="errorMessage(vForm.dataBasic.Dua)"
          disabled
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="4">
        <CInput
          size="sm"
          :label="`${$t('label.BolipuertoCertificateEntry')}`"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          class="align-items-center"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.dataBasic.ReceptionBpAct.$model"
          :is-valid="hasError(vForm.dataBasic.ReceptionBpAct)"
          :invalid-feedback="errorMessage(vForm.dataBasic.ReceptionBpAct)"
          disabled
        >
        </CInput>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import { mapState } from 'vuex';
import { Money } from "v-money";
import { DateFormater } from "@/_helpers/funciones";

function data() {
  return {
    masterId: process.env.VUE_APP_TP_BL_ID_MASTER,
    houseId: process.env.VUE_APP_TP_BL_ID_HOUSE,
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
      maxlength: 18,
    },
    Quantitymeasure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 0,
      masked: false,
      maxlength: 6,
    },
    blCargoMasterItems: [],
    blHouseName: '',
    blMasterName: '',
    PreviousPortActivityId: '',
    tpBLItems: [],
    CustomOperationItems: [],
    activoName: this.$t('label.ACTIVO'),

    VesselList: [],
    VisitOrItineraryList: [],
    ActivityList: [],
    PolList: [],
    PodList: [],

    GroupList: [],
    ClassificationList: [],
    CommodityList: [],
  };
}

//-----------------------   Method    ------------------------
async function getListApisDataBasic() {
  this.$store.state.yardManagement.loading = true;
  let peticiones = [
    this.$http.ejecutar("GET", "TpBl-list", { Filter: 'ACTIVO' }),
    this.$http.ejecutar("GET", "CustomOperation-list"),
    this.$http.ejecutar("GET", "YardBlTpCargoConfig-list"),
    this.$http.ejecutar("GET", "Vessel-list", { Filter: 'ACTIVO' }),
  ];
   await Promise.all(peticiones)
    .then(async(responses) => {
      //listar tipo de bl
      this.tpBLItems = responses[0].data.data ?? [];

      //listar regimen aduanal
      this.CustomOperationItems = responses[1].data.data;

      //listar grupo
      let GeneralCargoList = responses[2]?.data?.data?.[0] ?? {};
      this.GroupList = GeneralCargoList?.TpCargoJson ?? [];

      //lista buque
      this.VesselList = responses[3]?.data?.data ?? [];

      if (this.isEdit || this.fgHouse) {
        await this.getVisitOrItineraryList(true);
        
        let Vessel = this.VisitOrItineraryOptions.find(e => e.value == this.form.dataBasic.VisitIdOrItineraryId) ?? {};
        if (!this.validateBooking && Vessel?.Json?.VisitId) {
          await this.getPortActivityListByVisitList(true, Vessel.Json.VisitId);
        }else if (Vessel?.Json?.ItineraryId) {
          await this.getPortActivityList();
        }
       
        await this.getPorts(true);
        
        let GroupData = this.GroupList.find((val) => val.TpCargoId == this.form.dataBasic.TpCargoId);
        this.form.dataBasic.FgClassification = GroupData?.FgClassification ?? false;
        this.ClassificationList = GroupData?.ClassificationJson ? GroupData?.ClassificationJson : [];
        await this.getCommodity(true);
      }
      
      this.$store.state.yardManagement.loading = false;
    })
    .catch((err) => {
      this.$store.state.yardManagement.loading = false;
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
}

async function getVisitOrItineraryList(loading = false) {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get("YardBlCargoVisitItinerary-by-CompanyBranchId", { VesselId: this.form.dataBasic.VesselId, CompanyBranchId: this.branch.CompanyBranchId })
  .then(response => {
    this.VisitOrItineraryList = response.data.data ? response.data.data : [];
    this.$emit('VisitOrItineraryList', this.VisitOrItineraryList);
  }).catch( err => {
      this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
      });
  })
  .finally(() => {
    this.$store.state.yardManagement.loading = loading;
  });
}

async function getPortActivityList(Loading) {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get("PortActivity-list", { Filter: 'ACTIVO' })
    .then((response) => {
      this.ActivityList = response?.data?.data ?? [];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    }).finally(() => {
      this.$store.state.yardManagement.loading = Loading;
    });
}

async function getPortActivityListByVisitList(Loading, id) {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get("PortActivity-list-by-VisitId", { VisitId: id })
    .then((response) => {
      this.ActivityList = response?.data?.data ?? [];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    }).finally(() => {
      this.$store.state.yardManagement.loading = Loading;
    });
}

async function getPorts(Loading) {
  if (this.form.dataBasic.PortActivityId) {
    this.$store.state.yardManagement.loading = true;
    let Vessel = {};
    if (this.form.dataBasic.VisitIdOrItineraryId) {
      Vessel = this.VisitOrItineraryOptions.find(e => e.value == this.form.dataBasic.VisitIdOrItineraryId) ?? {};
    }
    await this.$http.get("YarBlCargoPort-list", {CompanyBranchId: this.branch.CompanyBranchId, PortActivictyId: this.form.dataBasic.PortActivityId, VisitId: Vessel?.Json?.VisitId ?? '', ItineraryId: Vessel?.Json?.ItineraryId ?? ''})
      .then((response) => {
        let List = response?.data?.data ?? [];
        this.PolList = List;
        this.PodList = List;
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      }).finally(() => {
        this.$store.state.yardManagement.loading = Loading;
      });
  }else{
    this.PolList = [];
    this.PodList = [];
    this.form.dataBasic.Pol = '';
    this.form.dataBasic.Pod = '';
  }
}

async function getCommodity(Loading) {
  if (this.form.dataBasic.TpCargoId) {
    this.$store.state.yardManagement.loading = true;
    await this.$http.get("Commodity-by-TpcargoId", { TpCargoId: this.form.dataBasic.TpCargoId })
      .then((response) => {
        this.CommodityList = response?.data?.data ?? [];
        this.$emit('UpdateCommodityList', this.CommodityList);
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      }).finally(() => {
        this.$store.state.yardManagement.loading = Loading;
      });
  }
}

function getClassificationAndCommodity(event){
  this.form.dataBasic.TpCargoId = event?.target?.value ?? ''; 
  this.form.dataBasic.TpCargoClassifId = '';
  this.ClassificationList = [];
  this.form.dataBasic.CommodityId = '';
  this.CommodityList = [];
  this.form.dataBasic.headingJson = [];
  this.$emit('UpdateCommodityList', this.CommodityList);
  if (this.form.dataBasic.TpCargoId) {
    let GroupData = this.GroupList.find((val) => val.TpCargoId == this.form.dataBasic.TpCargoId);
    this.form.dataBasic.FgClassification = GroupData?.FgClassification ?? false;
    this.ClassificationList = GroupData?.ClassificationJson ? GroupData?.ClassificationJson : [];
    this.getCommodity(false);
  }else{
    this.form.dataBasic.FgClassification = false;
  }
}

function onInputVesselId() {
  this.form.dataBasic.VisitIdOrItineraryId = '';
  this.form.dataBasic.Pol = '';
  this.form.dataBasic.Pod = '';
  this.form.dataBasic.PortActivityId = '';
  this.VisitOrItineraryList = [];
  if (this.form.dataBasic.VesselId) {
    this.getVisitOrItineraryList();
  }
}
  

async function onInputVisitIdOrItineraryId() {
  let Vessel = this.VisitOrItineraryOptions.find(e => e.value == this.form.dataBasic.VisitIdOrItineraryId) ?? {};
  this.form.dataBasic.Pol = '';
  this.form.dataBasic.Pod = '';
  this.form.dataBasic.PortActivityId = '';
  if (!this.validateBooking && Vessel?.Json?.VisitId) {
    await this.getPortActivityListByVisitList(true, Vessel.Json.VisitId);
  }else if (Vessel?.Json?.ItineraryId) {
    await this.getPortActivityList();
  }
  this.$store.state.yardManagement.loading = false;
}

function onChangePortActivityId() {
  this.form.dataBasic.Pol = '';
  this.form.dataBasic.Pod = '';

  this.form.consignee.ClientId = '';
  this.form.consignee.ConsigneeRif = '';
  this.form.consignee.ConsigneeAddress =  '';
  this.form.consignee.ConsigneeName =  '';
  this.form.consignee.ConsigneePrimaryPhone = '';
  this.form.consignee.ConsigneeSecondaryPhone =  '';
  this.form.consignee.ConsigneePrimaryEmail =  '';
  this.form.consignee.ConsigneeSecondaryEmail =  '';

  this.PreviousPortActivityId = this.form.dataBasic.PortActivityId ?? '';

  if (this.form.dataBasic.PortActivityId == process.env.VUE_APP_PORT_ACTIVITY_ID_IMPORT) {
    this.form.dataBasic.TpBlId = this.form.dataBasic.TpBlId.toUpperCase() == process.env.VUE_APP_TP_BL_ID_MASTER ? this.form.dataBasic.TpBlId : '';
  }

  this.getPorts(false);
}

function onPortActivityAlert() {
  if (this.form.PackagingList.length > 0) {
    this.$swal
      .fire(this.alertProperties({
          text: `${this.$t('label.changeActivity')}`,
      }))
      .then((result) => {
      if (result.isConfirmed) {
        this.onChangePortActivityId();
      }else{
        this.form.dataBasic.PortActivityId = this.PreviousPortActivityId ?? '';
      }
    });
  }else{
    this.onChangePortActivityId();
  }
}

function formatDateTimeWithSlash(date) {
  return date ? DateFormater.formatDateTimeWithSlash(date) : 'N/A';
}

async function resetForm() {
  //dataBasic
  this.form.dataBasic = {
    BlCargoId: '',
    bl: '',
    TpBlId: '',
    BlCargoMasterId: '',
    MasterBlNro: '',
    VesselId: '',
    VisitIdOrItineraryId: '',
    PortActivityId: '',
    Pol: '',
    Pod: '',
    TpCargoId: '',
    TpCargoClassifId: '',
    CommodityId: '',
    BookingNro: '',
    Weight: '',
    WeightReceived: '',
    WeightDispatch: '',
    WeigthCertified: '',
    Procedence: '',
    Volumen: '',
    VolumenReceived: '',
    VolumenDispatch: '',
    VolumenCertified: '',
    Quantity: '',
    QuantityReceived: '',
    QuantityDispatched: '',
    QuantityRemainDispatched: '',
    QuantityCertified: '',
    Viscosity: '',
    Temperature: '',
    headingJson: [],
    customsRegime:'',
    ReceptionBpAct: '',
    Dua: '',
    Status: 1,
    FgClassification: false,
  };
  this.blHouseName = '';
  this.blMasterName = '';
  this.PreviousPortActivityId = '';

  //data array
  this.tpBLItems = [];
  this.CustomOperationItems = [];
  this.blCargoMasterItems = [];
  this.ActivityList = [];
  this.VisitOrItineraryList = [];
  this.PolList = [];
  this.PodList = [];
  this.GroupList = [];
  this.ClassificationList = [];
  this.CommodityList = [];
  this.$emit('UpdateCommodityList', this.CommodityList);
}

//-----------------------   Computed   ------------------------
function checkChargeBlType() {
  return  ((this.form.dataBasic.TpBlId == this.masterId.toUpperCase()) && (this.edit))  ?  true : false;
}

function statusSelectColor() {
  return this.form.dataBasic.Status === 1;
}

function blTypeOptions() {
  let Import = this.form.dataBasic.PortActivityId == process.env.VUE_APP_PORT_ACTIVITY_ID_IMPORT;
  var chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
   this.tpBLItems.map(e => {
    if (Import ? e.TpBlId != process.env.VUE_APP_TP_BL_ID_BOOKING : true) {
      chart.push({
        value: e.TpBlId, 
        label: e.TpBlName,
      })   
    }
  })
  return chart;
}

function blCargoMasterOptions() {
  var chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  !this.checkCharge && this.blCargoMasterItems.map(e => {
    chart.push({
      value: e.BlCargoMasterId,
      label: e.BlNro
    })    
  })
  return chart;
}

function VesselOptions(){
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.VesselList.map(function(e){
    chart.push({
      ...e,
      value: e.VesselId, 
      label: e.VesselName,
    })
  })
  return chart;
}

function VisitOrItineraryOptions() {
  var chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.VisitOrItineraryList.map(e => {
    chart.push({
      value: !this.validateBooking ? (e.VisitId ?? e.ItineraryId) : e.ItineraryId, 
      label: `${e.Voyage} - ${e.LabelArrival ?? ''} ${formatDateTimeWithSlash(e.Arrival)}`,
      Json: {...e}
    })    
  })
  return chart;
}

function ActivityOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.ActivityList.map(e => {
    chart.push({
      value: e.PortActivityId, 
      label: e['PortActivityName'+_lang],
    })    
  })
  return chart;
}

function PolOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.PolList.map(e => {
    chart.push({
      value: e.PortId, 
      label: `${e.PortName}, ${e.PortCode}`,
    })    
  })
  return chart;
}

function PodOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.PodList.map(e => {
    chart.push({
      value: e.PortId, 
      label: `${e.PortName}, ${e.PortCode}`,
    })    
  })
  return chart;
}

function GroupOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.GroupList.map(e => {
    chart.push({
      value: e.TpCargoId, 
      label: e.TpCargoName
    })    
  })
  return chart;
}

function ClassificationOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.ClassificationList.map(function(e){
    chart.push({
      value: e.TpCargoClassifId, 
      label: e['ClassificationName'+_lang],
    })    
  })
  return chart;
}

function checkCharge() {
  return  this.form.dataBasic.TpBlId === this.houseId.toUpperCase() ?  false : true;
}

function customsRegimeOptions() {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  var chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.CustomOperationItems.map(e => {
    chart.push({
      value: e.CustomOperationId,
      label: e[`CustomOperationDs${_lang}`]
    })    
  })
  return chart;
}

function validateBl() {
  return process.env.VUE_APP_BL_STATUS_ID_CERTIFICATE == this.Bl?.BlStatus?.toUpperCase();
}

export default {
  name: 'dataBasic-tab',
  components: {
      Money
  },
  data,
  props: {
    form: {
      type: Object,
      required: true,
      default: () => {},
    },
    vForm: Object,
    Bl: Object,
    TpBlName: {
      type: String,
      required: true,
      default: () => '',
    },
    isEdit: Boolean,
    showModal: Boolean,
    valid: Boolean,
    validCargoMaster: Boolean,
    Tab: Boolean,
    fgHouse: Boolean,
    fgInfo: Boolean,
    validateBooking: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  methods: {
    getListApisDataBasic,
    getVisitOrItineraryList,
    getPortActivityListByVisitList,
    getPortActivityList,
    getPorts,
    getCommodity,
    getClassificationAndCommodity,
    onInputVesselId,
    onInputVisitIdOrItineraryId,
    onChangePortActivityId,
    onPortActivityAlert,
    formatDateTimeWithSlash,
    statusSelectColor,
    resetForm
  },
  computed:{
    customsRegimeOptions,
    checkChargeBlType,
    blTypeOptions,
    blCargoMasterOptions,
    VesselOptions,
    VisitOrItineraryOptions,
    ActivityOptions,
    PolOptions,
    PodOptions,
    GroupOptions,
    ClassificationOptions,
    checkCharge,
    validateBl,
    ...mapState({
      branch: state => state.auth.branch,
      BlCargoMasterId: state => state.yardManagement.BlCargoMasterId,
      CargoId: state => state.yardManagement.dataContainer.CargoId,
      loading: state => state.yardManagement.loading,
    })
  },
  watch: {
    showModal: async function (Newval) {
      if (!Newval) {
        this.resetForm();
      }
    },
    Tab: async function (Newval) {
      if (Newval) 
        await this.getListApisDataBasic();
    },
  }
};
</script>