<template>
	<div>
		<CRow class="mb-2">
			<CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
				<h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> 
					{{ this.isEdit ? `${this.$t('label.edit')} ${this.$t('label.container')}: ${this.ContainerName}` : `${this.$t('label.container')}`}} 
				</h6>
			</CCol>
		</CRow>
		<div class="mb-2" v-show="showIndex == 1">
			<CRow class="mb-3">
				<CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
					<CButton
						color="edit"
						class="mr-2"
						v-c-tooltip="{
							content: $t('label.return'),
							placement: 'top',
						}"
						size="sm"
						@click="$emit('Close')"
					>
						<CIcon name="cil-chevron-left-alt" />
					</CButton>
				</CCol>
			</CRow>
			<CRow class="mt-2 justify-content-center">
				<CCol sm="12" lg="10" class="p-0">
					<CRow class="m-0">
						<CCol sm="12" lg="6">
							<CInput
								v-uppercase
								size="sm"
								addLabelClasses="text-right required"
								:label="$t('label.containerCode')"
								:horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
								v-model="$v.ContainerCode.$model"
								:is-valid="hasError($v.ContainerCode)"
								:invalid-feedback="errorMessage($v.ContainerCode)"
								:maxlength="11"
							>
								<template #append>
									<CButton
										color="watch"
										square
										size="sm"
										class="rounded"
										v-c-tooltip="{
											content: $t('label.search'), 
											placement: 'top-end'
										}" 
										@click="ContainerSearch()"
										style="padding: 0.15rem 0.4rem;"
									>
										<CIcon name="cil-search"/>
									</CButton>
								</template>
							</CInput>
						</CCol>
						<CCol sm="12" lg="6">
							<CSelect
								v-uppercase
								size="sm"
								addLabelClasses="text-right required"
								:label="$t('label.newShippingLine')"
								:horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
								v-model="$v.ShippingLine.$model"
								:is-valid="hasError($v.ShippingLine)"
								:invalid-feedback="errorMessage($v.ShippingLine)"
								:options="ShippingLineOptions"
								:disabled="!NewEntry"
							/>
						</CCol>
						<CCol sm="12" lg="6">
							<CSelect
								v-uppercase
								size="sm"
								addLabelClasses="text-right required"
								:label="$t('label.status')"
								:horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
								v-model="$v.Status.$model"
								:is-valid="hasError($v.Status)"
								:invalid-feedback="errorMessage($v.Status)"
								:options="ContainerStatusOptions"
								:disabled="!NewEntry"
							/>
						</CCol>
						<CCol sm="12" lg="6">
							<div class="form-group form-row mb-2">
								<label class="col-form-label-sm col-sm-12 col-lg-5 text-right required">ISO</label>
								<div class="input-group col-sm-12 col-lg-7">
									<v-select
										id="v-select-small"
										:class="$v.Iso.$dirty ? (!$v.Iso.$error ? 'select-client--correct' : 'select-client--error') : ''"
										:placeholder="$t('label.select')"
										:options="IsoOptions"
										:reduce="option => option.value" 
										v-model="Iso"
										style="width: 100%; height: 29px;"
										:disabled="!NewEntry"
									>
										<template #no-options="{}">
											{{$t('label.noResultsFound')}}
										</template>
									</v-select>
									<div class="text-invalid-feedback" v-if="$v.Iso.$error">
										{{ errorMessage($v.Iso) }}
									</div>
								</div>
							</div>
						</CCol>
						<CCol sm="12" lg="6">
							<CInput
								v-uppercase
								size="sm"
								addLabelClasses="text-right required"
								:label="$t('label.size')"
								:horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
								v-model="$v.Size.$model"
								:is-valid="hasError($v.Size)"
								:invalid-feedback="errorMessage($v.Size)"
								disabled
							/>
						</CCol>
						<CCol sm="12" lg="6">
							<CInput
								v-uppercase
								size="sm"
								addLabelClasses="text-right required"
								:label="$t('label.type')"
								:horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
								v-model="$v.Type.$model"
								:is-valid="hasError($v.Type)"
								:invalid-feedback="errorMessage($v.Type)"
								disabled
							/>
						</CCol>
						<CCol sm="12" lg="6">
							<div class="form-group form-row mb-2">
								<label class="col-form-label-sm col-sm-12 col-lg-5 text-right">SHIPPER OWNED</label>
								<div class="d-flex justify-content-end col-sm-12 col-lg-auto ">
									<CSwitch
										color="watch"
										variant="3d"
										type="checkbox"
										:checked.sync="FgShipperOwned"
										:disabled="!NewEntry"
									/>
								</div>
							</div>
						</CCol>
						<CCol sm="12" lg="6">
							<CInput
								v-uppercase
								size="sm"
								:addLabelClasses="FgShipperOwned ? 'text-right required' : 'text-right'"
								label="SHIPPER OWNED"
								:horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
								v-model="$v.ShipperOwned.$model"
								:is-valid="hasError($v.ShipperOwned)"
								:invalid-feedback="errorMessage($v.ShipperOwned)"
								:disabled="!NewEntry||!FgShipperOwned"
							/>
						</CCol>
					</CRow>
				</CCol>
				<CCol sm="12" class="d-flex justify-content-end mb-2">
					<CButton
						color="watch"
						size="sm"
						class="mr-1"
						@click="ContinueNewEntry()"
					>
						<CIcon name="continue" />&nbsp; {{$t('label.Continue')}}
					</CButton>
				</CCol>
			</CRow>
		</div>
		<div v-show="showIndex == 2">
			<ContainerInfo
				:active="showIndex == 2"
				:SearchContainer="SearchContainer"
				:NewEntryForm="NewEntryForm"
				:NewGeneralInfo="NewGeneralInfo"
				@Close="showIndex = 1"
				@SubmitNewEntry="NewRegister"
			/>
		</div>
	</div>
</template>
<script>
import { mapState } from 'vuex';
import General from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import { StepOne } from '@/_validations/yard-management/movements/newEntryValidations';
import ModalMixin from '@/_mixins/modal';
import ContainerInfo from "./containerInfo/index";

//data
function data() {
	return {
		SearchContainer: false,
		NewEntry: false,
		ClientModal: false,
		FgShipperOwned: false,
		showIndex: 1,
		ContainerName: '',
		CargoId: '',
		ContainerId: '',
		ContainerCode: '',
		PortActivity: '',
		ShippingLine: '',
		ShippingLineList: [],
		Client: '',
		Procedence: '',
		Itinerary: '',
		Booking: '',
		Status: '',
		ContainerStatus: [],
		Size: '',
		Type: '',
		Iso: '',
		IsoList: [],
		ShipperOwned: '',
		NewGeneralInfo: [],
		NewEntryForm: {},
	};
}

//methods
async function ContainerSearch() {
	try {
		await this.$v.ContainerCode.$touch();
		if (!this.$v.ContainerCode.minLength||!this.$v.ContainerCode.required) {
			throw 'error'
		}
		this.$store.state.yardManagement.loading = true;
		let CargoJson = {
			BlCargoId: this.form.dataBasic?.BlCargoId ?? '',
			ContainerCode: this.ContainerCode,
			CompanyBranchId: this.branch.CompanyBranchId,
		}
		this.$http.post('YardContainerSearch', CargoJson, { root: 'CargoJson' })
			.then(async response => {
				let List = response.data.data ?? [];
				if (List.length != 0 && Object.entries(List[0]).length > 1) {
					if (!this.isEdit || this.ContainerCode == this.ContainerName) {
						let CargoMasterJson = List[0]?.CargoMasterJson&&List[0]?.CargoMasterJson.length!=0 ?
							List[0]?.CargoMasterJson[0] : {};
						if (this.Itinerary == CargoMasterJson.ItineraryId ?? '') {
							this.FgShipperOwned = List[0].FgActShipperOwn ?? false;
							this.CargoId = List[0].CargoId ?? '',
							this.ContainerId = List[0].ContainerId ?? '',
							this.PortActivity = CargoMasterJson.PortActivictyId ? (CargoMasterJson.PortActivictyId).toUpperCase() : '';
							this.Client = CargoMasterJson.ClientTpId ?? '';
							this.Procedence = CargoMasterJson.Procedence ?? '';
							this.Itinerary = CargoMasterJson.ItineraryId ?? '';
							this.Booking = CargoMasterJson.BookingNro ?? '';
							this.Status = CargoMasterJson.TpCargoStatusId ?? '';
							this.ShipperOwned = List[0].ShipperOwn ?? '';

							await this.NewEntryServices();
							this.ShippingLine = CargoMasterJson.ShippingLineId ?? '';
							this.Iso = List[0].MetadataId ?? '';
							this.NewEntry = true;
							this.SearchContainer = true;
							this.$v.$touch();
							this.NewEntryForm = {
								TpCargoStatusId: CargoMasterJson.TpCargoStatusId ?? '',
								TpCargoClassId: List[0].TpCargoClassId ?? '',
								LoadPortId: CargoMasterJson.LoadPortId ?? '',
								DischargePortId: CargoMasterJson.DischargePortId ?? '',
								DeliveryPortId: CargoMasterJson.DeliveryPortId ?? '',
								OriginalPortLoadingId: CargoMasterJson.OriginalPortLoadingId ?? '',
								DescriptionType: CargoMasterJson.DescriptionType ?? '',
								Weigth: CargoMasterJson.Weigth ?? 0,
								UnitMeasureWeigth: CargoMasterJson.UnitMeasureWeigth ?? '',
								Vgm: CargoMasterJson.Vgm ?? 0,
								UnitMeasureVgm: CargoMasterJson.UnitMeasureVgm ?? '',
								TpCargoConditionId: CargoMasterJson.TpCargoConditionId ?? '',
								DryReefer: CargoMasterJson.DryReefer ? true : false,
								UnitMeasureRf: CargoMasterJson.UnitMeasureRf ?? '',
								Plugged: CargoMasterJson.DryReefer ? true : false,
								TpPowerPlugId: CargoMasterJson.TpPowerPlugId ?? '',
								ReeferSetting: CargoMasterJson.ReeferSetting ?? '',
								MinimumRangeRf: CargoMasterJson.MinimumRangeRf ?? '',
								MaximumRangeRf: CargoMasterJson.MaximumRangeRf ?? '',
								UnitMeasureDim: CargoMasterJson.UnitMeasureDim ?? '',
								DimFront: CargoMasterJson.DimFront ?? 0,
								DimBack: CargoMasterJson.DimBack ?? 0,
								DimLeft: CargoMasterJson.DimLeft ?? 0,
								DimRight: CargoMasterJson.DimRight ?? 0,
								DimTop: CargoMasterJson.DimTop ?? 0,
								TranshipmentPortId: CargoMasterJson.TranshipmentPortId ??'',
								LastVisitJson: List[0].LastVisitJson ?? [],
								LastYardJson: List[0].LastYardJson ?? [],
							};
						}else {
							this.$notify({
								group: 'container',
								title: '¡Error!',
								text: 'ERROR',
								type: "error"
							});
						}
					} else {
						this.Reset(false);
						this.$v.ContainerCode.$touch();
						this.$notify({
							group: 'container',
							title: '¡Error!',
							text: 'ERROR',
							type: "error"
						});
					}
				} else {
					this.Reset(false);
					this.$v.ContainerCode.$touch();
					await this.NewEntryServices();
				}
			})
			.catch(err => {
				this.$notify({
					group: 'container',
					title: '¡Error!',
					text: err,
					type: "error"
				});
			})
			.finally(() => {
				this.$store.state.yardManagement.loading = false;
			});
	} catch (error) {
		this.Reset(false);
		this.$v.ContainerCode.$touch();
	}
}

async function NewEntryServices() {
	let Requests = [
		this.$http.ejecutar("GET", "TpCargoDetail-by-codiso?CodIso"),
    ];

  	await Promise.all(Requests)
		.then(async responses => {
			this.IsoList = responses[0].data.data ?? [];
		})
		.catch(err => {
			this.$notify({
				group: 'container',
				title: '¡Error!',
				text: err,
				type: "error"
			});
		})
	await this.YardContainerNewGeneralInfo();
}

async function YardContainerNewGeneralInfo() {
	this.ShippingLine = '';
	this.NewEntryForm.LoadPortId = '';
	this.NewEntryForm.DischargePortId = '';
	this.$store.state.yardManagement.loading = true;
	await this.$http.get('YardContainerNewGeneralInfo', {ItineraryId: this.Itinerary})
		.then(async response => {
			this.NewGeneralInfo = response.data.data ?? [];
			if (this.NewGeneralInfo.length != 0) {
				this.ShippingLineList = this.NewGeneralInfo[0]?.ServiceLineMasterJson ?? [];
				this.ContainerStatus = this.NewGeneralInfo[0]?.TpCargoStatusJson ?? [];
			}else{
				this.ShippingLineList = [];
				this.ContainerStatus = [];
			}
			this.NewEntry = true;
		})
		.catch(async err => {
			this.ShippingLineList = [];
			this.ContainerStatus = [];
			this.$notify({
				group: 'container',
				title: '¡Error!',
				text: err,
				type: "error"
			});
		})
		.finally(() => {
			this.$store.state.yardManagement.loading = false;
		});
}

async function ContinueNewEntry() {
	try {
		await this.$v.$touch();
		if (this.$v.PortActivity.$error||this.$v.ShippingLine.$error||this.$v.Client.$error||
			this.$v.Procedence.$error||this.$v.Itinerary.$error||this.$v.Booking.$error||this.$v.Status.$error||
			this.$v.Size.$error||this.$v.Type.$error||this.$v.Iso.$error||
			this.$v.ShipperOwned.$error||!this.$v.ContainerCode.minLength||!this.$v.ContainerCode.required) {
			throw this.$t('label.errorsPleaseCheck');
		}
		this.$store.state.yardManagement.loading = true;
		setTimeout(async()=>{
			let IsoData = this.IsoList.find(item => item.value == this.Iso);
			this.NewEntryForm = { 
				...this.NewEntryForm,
				TpCargoConditionId: this.NewEntryForm?.TpCargoStatusId == this.Status ? this.NewEntryForm?.TpCargoConditionId : '',
				TpCargoStatusId: this.Status,
				CargoId: this.CargoId,
				ContainerId: this.ContainerId,
				ContainerCode: this.ContainerCode,
				PortActivity: this.PortActivity,
				ShippingLine: this.ShippingLine,
				Client: this.Client,
				Procedence: this.Procedence,
				Itinerary: this.Itinerary,
				Booking: this.Booking,
				Status: this.Status,
				ContainerStatus: this.ContainerStatus,
				Size: IsoData.SizeId,
				Type: this.Type,
				Iso: this.Iso,
				IsoList: IsoData,
				FgShipperOwned: this.FgShipperOwned,
				ShipperOwned: this.ShipperOwned,
			}
			this.showIndex = 2;
			this.$store.state.yardManagement.loading = false;
		},10)
		
	} catch (error) {
		this.$notify({
			group: 'container',
			title: '¡Error!',
			text: error,
			type: "error"
		});
	}
}

async function NewRegister(Container) {
	if (!this.isList) {
		await this.$emit('changeContainer', Container);
	}
	if (this.isList || this.isEdit) {
		await this.$emit('updateContainer', Container);
	}
}

function Reset(All) {
	this.SearchContainer = false;
	this.NewEntry = false;
	this.ClientModal = false;
	this.FgShipperOwned = false;
	this.showIndex = 1;
	this.CargoId = '',
	this.ContainerId = '',
	this.ContainerCode = All ? '': this.ContainerCode;
	this.PortActivity = this.form?.dataBasic.PortActivityId ?? '';
	this.ShippingLine = '';
	this.ShippingLineList = [];
	this.Client = '';
	this.Itinerary = this.form?.dataBasic.VisitIdOrItineraryId ?? '';
	this.Procedence = '';
	this.Booking = '';
	this.Status = '';
	this.ContainerStatus = [];
	this.Size = '';
	this.Type = '';
	this.Iso = '';
	this.IsoList = [];
	this.ShipperOwned = '';
	this.NewGeneralInfo = [];
	this.NewEntryForm = {};
	this.$v.$reset();
	if (All) {
		this.ContainerName = '';
	}
}

//computed
function ShippingLineOptions() {
	if(this.ShippingLineList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.ShippingLineList.map(function(e){
      chart.push({
        value: e.ShippingLineId,
        label: `${e.ShippingLineName} (${e.ShippingLineCode})`,
      })
    })
    return chart;
  }
}

function ContainerStatusOptions() {
  if(this.ContainerStatus.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.ContainerStatus.map(function(e){
      chart.push({
        value: e.TpCargoStatusId,
        label: e.TpCargoStatusName,
      })
    })
    return chart;
  }
}

function IsoOptions() {
	if(this.IsoList.length === 0){
		return [{
		value: '', 
		label: this.$t('label.select'),
		}];
	}else{
		let container = this.form?.PackagingList.find(e => e.PackagingId && e.IdX == this.SerialItem.PackagingIdX && e.FgRequiredSerialization && e.Status == 1);
		let chart = [{
		value: '', 
		label: this.$t('label.select'),
		}]
		this.IsoList.map(function(e){
			if (e.TpCargoDetailId == container?.TpCargoDetailId) {
				chart.push({
					value: e.MetadataId,
					label: e.MetadataDesc,
				})
			}
		})
		return chart;
	}
}
		
export default{
	name: 'bookingContainer',
	data,
	props: {Active: Boolean, isEdit: Boolean, isList: Boolean, form: Object, SerialItem: Object},
	mixins: [General, ModalMixin],
	components: {
		ContainerInfo,
	},
	validations(){
		return StepOne(this.FgShipperOwned, this.Itinerary, true);
	},
	directives: UpperCase,
	methods: {
		ContainerSearch,
		NewEntryServices,
		YardContainerNewGeneralInfo,
		ContinueNewEntry,
		NewRegister,
		Reset,
	},
	computed: {
		ShippingLineOptions,
		ContainerStatusOptions,
		IsoOptions,
		...mapState({
			branch: state => state.auth.branch,
		})
	},
	watch:{
		Active: function (NewVal) {
			if (NewVal) {
				this.$store.state.yardManagement.loading = true;
				this.PortActivity = this.form?.dataBasic.PortActivityId ?? '';
				this.Itinerary = this.form?.dataBasic.VisitIdOrItineraryId ?? '';
				if (this.isEdit) {
					this.ContainerCode = this.SerialItem?.Serial ?? '';
					this.ContainerName = this.SerialItem?.Serial ?? '';
					this.ContainerSearch();
				}else{
					this.ContainerCode = this.SerialItem?.Serial ?? '';
					this.NewEntry = true;
					this.SearchContainer = true;
					this.$v.ContainerCode.$touch();
					this.NewEntryServices();
				}
			}else {
				this.Reset(true);
			}
		},
		Iso: function (NewVal) {
			this.Size = '';
			this.Type = '';
			if (NewVal) {
				let IsoData = this.IsoList.find((item) => { return  NewVal === item.MetadataId });
				this.Type = IsoData?.TpCargoName ?? '';
        		this.Size = IsoData?.Size ?? '';
			}
		}
	}
}
</script>