import {
    phoneTest,
    especiales,
    onlyAlphanumeric6,
} from '@/_validations/validacionEspeciales'

import {
    required,
    email,
    helpers,
    minLength,
    maxLength,
    minValue,
    decimal
  } from "vuelidate/lib/validators";

import { NumberFormater } from "@/_helpers/funciones";
  
const mobileNotReq = (value) => !helpers.req(value) || phoneTest(value);
const emailToLower = (value) => email(value.toLowerCase());

export default (consolidator,validCargo, fgHouse) => {
    let ConsolidatorId = false;
    let ConsolidatorRif = false;
    let ConsolidatorName = false;
    let ConsolidatorAddress = false;
    let ConsolidatorPrimaryPhone = false;
    let ConsolidatorSecondaryPhone = false;
    let ConsolidatorPrimaryEmail = false;
    let ConsolidatorSecondaryEmail = false;
    let validCargoMaster = false;

    if(validCargo){
        validCargoMaster = { required };
    }

    if (!consolidator) {
        ConsolidatorId = fgHouse ? { required } : {};
        ConsolidatorRif = {minLength: minLength(9), maxLength: maxLength(50)};
        ConsolidatorName = {especiales, maxLength: maxLength(50)};
        ConsolidatorAddress = {especiales, maxLength: maxLength(100)};
        ConsolidatorPrimaryPhone = {mobileNotReq, minLength: minLength(11), maxLength: maxLength(15)};
        ConsolidatorSecondaryPhone = { mobileNotReq, minLength: minLength(11),  maxLength: maxLength(15)};
        ConsolidatorPrimaryEmail = {emailToLower, maxLength: maxLength(100)},
        ConsolidatorSecondaryEmail = {emailToLower, maxLength: maxLength(100)}
    }
    return {
        form: {
            dataBasic: {
                bl:{
                    required,
                    onlyAlphanumericGuion: onlyAlphanumeric6,
                    minLength:minLength(1),
                    maxLength:maxLength(30),
                },
                BlCargoId:{
                    
                },
                TpBlId:{
                    required
                },
                BookingNro:{
                    minValue:(1),
                    maxValue:(16),
                },
                BlCargoMasterId: validCargoMaster,
                MasterBlNro: {},
                Procedence:{
                    minLength:minLength(1),
                    maxLength:maxLength(30),
                },
                Weight:{
                    required(Value) { return NumberFormater.setNum(Value) > 0 || NumberFormater.setNum(Value) < 0 ? true : false },
                    decimal,
                },
                Volumen:{
                    NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
                    required(Value) { return fgHouse ? (NumberFormater.setNum(Value) > 0 || NumberFormater.setNum(Value) < 0 ? true : false) : true },
                },
                Quantity:{
                    required(Value) { return NumberFormater.setNum(Value) > 0 || NumberFormater.setNum(Value) < 0 ? true : false },
                },
                TpCargoId: { required },
                headingJson:{
                    required(Value) { return Value.length > 0 ? true : false },
                },
                customsRegime: { required },
                ReceptionBpAct:{
                    onlyAlphanumericGuion: onlyAlphanumeric6,
                    minLength: minLength(1),
                    maxLength: maxLength(20)
                },
                Dua:{
                    onlyAlphanumericGuion: onlyAlphanumeric6,
                    minLength: minLength(1),
                    maxLength: maxLength(20)
                },
            },
            serialization: {},
            imdg:{
                ImdgClassId:{
                    required
                },
                ImdgId:{
                    required
                },
                PackagingGroupId:{

                },
                UnNumberId:{

                },
                DescriptionOfGoodsDGS:{
                    
                }
            },
            seller:{
                SellerName:{
                    especiales, 
                    maxLength: maxLength(50)
                },
                SellerAddress:{
                    especiales, 
                    maxLength: maxLength(100)
                },
                SellerPrimaryPhone:{ 
                    mobileNotReq,
                    minLength: minLength(11), 
                    maxLength: maxLength(15) 
                },
                SellerSecondaryPhone:{  
                    mobileNotReq,
                    minLength: minLength(11), 
                    maxLength: maxLength(15) 
                },
                SellerPrimaryEmail:{ 
                    emailToLower, 
                    maxLength: maxLength(100) 
                },
                SellerSecondaryEmail:{
                    emailToLower, 
                    maxLength: maxLength(100)
                },
            },
            customBroke:{
                CustomBrokerId: fgHouse ? { required } : {},
                CustomBrokerRif:{
                    minLength: minLength(9), 
                    maxLength: maxLength(50)
                },
                CustomBrokerName:{
                    especiales, 
                    maxLength: maxLength(100)
                },
                CustomBrokerAddress:{
                    especiales, 
                    maxLength: maxLength(100)
                },
                CustomBrokerPrimaryPhone:{  
                    mobileNotReq,
                    minLength: minLength(11), 
                    maxLength: maxLength(15) 
                },
                CustomBrokerSecondaryPhone:{  
                    mobileNotReq,
                    minLength: minLength(11), 
                    maxLength: maxLength(15) 
                },
                CustomBrokerPrimaryEmail:{  
                    emailToLower, 
                    maxLength: maxLength(100) 
                },
                CustomBrokerSecondaryEmail:{
                    emailToLower, 
                    maxLength: maxLength(100)
                },
                consolidatorCheck: {
                    
                },
            },
            consolidator:{
                ConsolidatorId: ConsolidatorId,
                ConsolidatorRif: ConsolidatorRif,
                ConsolidatorName: ConsolidatorName,
                ConsolidatorAddress: ConsolidatorAddress,
                ConsolidatorPrimaryPhone: ConsolidatorPrimaryPhone,
                ConsolidatorSecondaryPhone: ConsolidatorSecondaryPhone,
                ConsolidatorPrimaryEmail: ConsolidatorPrimaryEmail,
                ConsolidatorSecondaryEmail:ConsolidatorSecondaryEmail 
            },
            consignee:{
                ConsigneeId: {
                   required
                },
                ConsigneeRif:{
                    minLength: minLength(9), 
                    maxLength: maxLength(50)
                },
                ConsigneeName:{
                    especiales, 
                    maxLength: maxLength(100)
                },
                ConsigneeAddress:{
                    especiales, 
                    maxLength: maxLength(100)
                },
                ConsigneePrimaryPhone:{ 
                    mobileNotReq,
                    minLength: minLength(11), 
                    maxLength: maxLength(15) 
                },
                ConsigneeSecondaryPhone:{  
                    mobileNotReq,
                    minLength: minLength(11), 
                    maxLength: maxLength(15) 
                },
                ConsigneePrimaryEmail:{ 
                    emailToLower, 
                    maxLength: maxLength(100) 
                },
                ConsigneeSecondaryEmail:{
                    emailToLower, 
                    maxLength: maxLength(100)
                },
            },
            notify:{
                NotifyToName:{
                    especiales, 
                    maxLength: maxLength(100)
                },
                NotifyToAddress:{
                    especiales, 
                    maxLength: maxLength(100)
                },
                NotifyToPrimaryPhone:{ 
                    mobileNotReq,
                    minLength: minLength(11), 
                    maxLength: maxLength(15) 
                },
                NotifyToSecondaryPhone:{  
                    mobileNotReq,
                    minLength: minLength(11), 
                    maxLength: maxLength(15) 
                },
                NotifyToPrimaryEmail:{  
                    emailToLower, 
                    maxLength: maxLength(100) 
                },
                NotifyToSecondaryEmail:{
                    emailToLower, 
                    maxLength: maxLength(100)
                },
            },
            DescriptionOfGoods:{
                DescriptionOfGoods: {required}
            },
            status:{
                Status:{
                    required
                },
                TransactionDate:{
                    required
                },
                Motive:{

                },
            },
        },
        document:{
            BlCargoDocumentId:{ 
            },
            PortActivictyDocumentCargoId:{
                required
            },
            BlCargoDocumentDs:{
               required,
               minLength: minLength(2), 
               maxLength: maxLength(50)
            },
        },
    }
}

